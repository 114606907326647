import { Component, OnInit } from '@angular/core';
import { DbService } from '../_services/db.service';
import {EinstellungenComponent} from './einstellungen.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'einstellungen-mobile',
  templateUrl: './einstellungen.component.mobile.html',
  styleUrls: ['../home/home.component.mobile.css']
})


export class EinstellungenMobileComponent extends  EinstellungenComponent{

  constructor(
    service: DbService) {super(service); }

}
