<div class="map-container">
  <div class="map-frame2">
    <div id="map"></div>
  </div>

  <div class="bgimg4"><div style="text-align: center;"><h2><strong>gefangener Fisch vom {{fund.datum | date:'dd.MM.yyyy'}}</strong></h2><br>

    <h2><strong>aktuelle Informationen zum gefangenen Fisch</strong></h2>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Fischart</strong></th><th><strong>Sender</strong></th><th><strong>Gewässer</strong></th>
      <th><strong>Länge</strong></th><th><strong>Gewicht</strong></th><th>Fundort</th>
      </thead>
      <tbody>
      <tr>
        <td>{{fund.fisch}}</td>
        <td>{{fund.sender}}</td>
        <td><div *ngIf="fund.flid===0">
            <mat-form-field>
              <input matInput placeholder="Gewässer wählen, Enter bestätigen" aria-label="Fluss" [(ngModel)]="fluss" (keyup.enter)="changeFluss()" [formControl]='fluesseControl' [matAutocomplete]="auto" >
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                <ng-container *ngIf="!isLoading">
                  <mat-option *ngFor="let fluss of filteredFluesse" [value]="fluss">
                    <span>{{ fluss.name }}</span>
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>
        </div>
          <div *ngIf="fund.flid > 0">
           {{fund.fluss}}
          </div></td>
            <td><input type="number" [(ngModel)]="fund.laenge"> cm</td>
            <td><input type="number" [(ngModel)]="fund.gewicht"> g</td>
            <td><input type="text" [(ngModel)]="fund.fort"></td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Datum</strong></th><th><strong>Notiz</strong></th><th><strong>Status</strong></th>
      <th><strong>Wanderdistanz</strong></th><th><strong>Wanderzeit</strong></th>
      </thead>
      <tbody>
      <tr>
        <td>{{fund.datum | date:'dd.MM.yyyy'}}</td>
        <!--<td><input type="date" [(ngModel)]="tag"></td>-->
        <td><textarea id="fund.notiz" cols="40" rows="4"  [(ngModel)]="fund.notiz"></textarea></td>
          <td><mat-form-field>
            <mat-select placeholder="Wählen Sie den Status" [(ngModel)]="fund.bearbeitet" name="send">
              <mat-option *ngFor="let stat of status" [value]="stat.id">
                {{stat.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          </td>
        <td><input type="number" [(ngModel)]="fund.distanz"></td>
        <td><input type="number" [(ngModel)]="fund.zeit"></td>
      </tr>
      </tbody>
    </table>
    <br><button (click)="save()">Änderungen speichern</button><button (click)="goBack()">Zurück zum Hauptmenü</button>
  <br>    <strong>{{info}}</strong><br>

    <div *ngIf="fund.fischid === 0">
      Der Code konnte vom Finder nicht identifiziert werden. Bitte wählen Sie den Code aus und bestätigen nach der Eingabe mit Enter.<br>
      <mat-form-field>
        <input matInput placeholder="Wählen Sie den Code" aria-label="Code" [(ngModel)]="code" (keyup.enter)="changeCode()" [formControl]="senderControl" [matAutocomplete]="auto3" >
        <mat-autocomplete #auto3="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
          <ng-container *ngIf="!isLoading">
            <mat-option *ngFor="let fisch of filteredCode" [value]="fisch">
              <span>{{ fisch.name }}</span>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div *ngIf="fund.fischid > 0">
      <h2><strong>Informationen zum ursprünglich besetzten Fisch</strong></h2>
      <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
        <thead>
        <th><strong>Fischart</strong></th><th><strong>Sendertyp</strong></th>
        <th><strong>Code</strong></th><th><strong>Aussatzdatum</strong></th><th><strong>Gewicht</strong></th>
        <th><strong>Länge</strong></th>
        </thead>
        <tbody>
        <tr >
          <td>{{fisch.fisch}}</td>
          <td>{{fisch.sender}}</td>
          <td><a routerLink="/fisch/{{fisch.id}}">{{fisch.code}}</a></td>
          <td>{{fisch.datum}}</td>
          <td>{{fisch.gewicht}} g</td>
          <td>{{fisch.laenge}} cm</td>
        </tr>
        </tbody>
      </table>
      <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
        <thead><th><strong>Projekt</strong></th>
        <th><strong>Besatzort</strong></th><th><strong>Gewässer</strong></th><th><strong>Land</strong></th>
        <th><strong>Markierer</strong></th>
        </thead>
        <tbody>
        <tr>
          <td><a routerLink="/besatzorte/{{projekt.id}}">{{projekt.name}}</a></td>
          <td><a routerLink="/besatzort/{{besatzort.id}}">{{besatzort.name}}</a></td>
          <td>{{projekt.fluss}}</td>
          <td>{{projekt.laendercode}}</td>
          <td>{{markierer.name}}</td>
        </tr>
        </tbody>
      </table><br><br>
    </div><br>
    <h2><strong>Informationen zum Finder</strong></h2>
    <div *ngIf="fund.findid > 0 || show">
      <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Nachname</strong></th><th><strong>Vorname</strong></th>
      <th><strong>Straße</strong></th><th><strong>PLZ</strong></th><th><strong>Ort</strong></th>
      </thead>
      <tbody>
      <tr>
        <td style="width: 20%"><input type="text" [(ngModel)]="finder.name"></td>
        <td style="width: 20%"><input type="text" [(ngModel)]="finder.vorname"></td>
        <td style="width: 20%"><input type="text" [(ngModel)]="finder.strasse"></td>
        <td style="width: 20%"><input type="text" [(ngModel)]="finder.plz"></td>
        <td style="width: 20%"><input type="text" [(ngModel)]="finder.ort"></td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Telefon</strong></th><th><strong>E-Mail</strong></th>
      <th><strong>Bank</strong></th><th><strong>IBAN</strong></th><th><strong>BIC</strong></th>
      </thead>
      <tbody>
      <tr>
        <td style="width: 20%"><input type="text" [(ngModel)]="finder.telefon"></td>
        <td style="width: 20%"><input type="text" [(ngModel)]="finder.email"></td>
        <td style="width: 20%"><input type="text" [(ngModel)]="finder.bank"></td>
        <td style="width: 20%"><input type="text" [(ngModel)]="finder.iban"></td>
        <td style="width: 20%"><input type="text" [(ngModel)]="finder.bic"></td>
      </tr>
      </tbody>
    </table></div>
    <div *ngIf="fund.findid == 0 && !show">
      <mat-form-field>
        <input matInput placeholder="Wähle einen Finder" [(ngModel)]="finder" (keyup.enter)="reloadFinder()" [formControl]='finderControl' [matAutocomplete]="auto2"><mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
        <ng-container *ngIf="!isLoading">
          <mat-option *ngFor="let kont of filteredFinder" [value]="kont">
            <span>{{ kont.name }}</span>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
      </mat-form-field>
      <br><strong>Zur Bestätigung der Auswahl Enter drücken.</strong><br><br>
    </div>
    <div *ngIf="fund.findid == 0">
      <button (click)="toggleShow()">Wechsel Auswählen / Anlegen</button>
    </div><br><br>
</div></div>
</div>
