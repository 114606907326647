import { Component, OnInit } from '@angular/core';
import { DbService } from '../_services/db.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {FischComponent} from './fisch.component';
import {AuthenticationService} from '../_services';

@Component({
  selector: 'app-fisch',
  templateUrl: './fisch.component.html',
  styleUrls: ['../app.component.css']})
export class FischDesktopComponent extends FischComponent {
  constructor(
    router: Router,
    service: DbService,
    route: ActivatedRoute,
    location: Location,
    authenticationService: AuthenticationService) {
    super(router, service, route, location, authenticationService);
  }
}
