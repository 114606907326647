<body><nav class="navbar navbar-expand-lg navbar-dark bg-dark" *ngIf="currentUser">
  <div class="navbar-brand" *ngIf="isMobileResolution">
    Menü
  </div><button class="navbar-toggler" type="button" (click)="toggleNavbar()">
  <span class="navbar-toggler-icon"></span>
</button>




  <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }">
    <ul class="navbar-nav mr-auto" (click)="toggleNavbar()">
      <li class="nav-item" >
        <a class="nav-link" routerLink="/">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/projekte">Projekte</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/fische">bes. Fische</a>
      </li>
      <li class="nav-item">
      <a class="nav-link" routerLink="/gefunden">gef. Fische</a>
      </li><div *ngIf="currentUser.rechte<2">
      <li class="nav-item">
        <a class="nav-link" routerLink="/gewaesser">Gewässer</a>
      </li></div><div *ngIf="currentUser.rechte<2">
      <li class="nav-item">
        <a class="nav-link" routerLink="/fischarten">Fischarten</a>
      </li></div><div *ngIf="currentUser.rechte<2">
      <li class="nav-item">
        <a class="nav-link" routerLink="/markierer">Markierer</a>
      </li></div><div *ngIf="currentUser.rechte<2">
      <li class="nav-item">
        <a class="nav-link" routerLink="/user">User</a>
      </li></div>
      <li class="nav-item">
        <a class="nav-link" (click)="logout()">Logout</a>
      </li>
    </ul>
  </div>
</nav>

<!-- main app container -->

<alert></alert>
<router-outlet></router-outlet>

</body>
