import { Component, OnInit, Directive } from '@angular/core';
import { DbService } from '../_services/db.service';
import {Einstellung} from '../_models/einstellung';
import {Auswahl} from '../_models/auswahl';


@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class EinstellungenComponent implements OnInit {

  einstellungen: Einstellung[];
  phasen: Auswahl[];
  typen: Auswahl[];
  erase: number[];
  erase2: number[];
  count = -1;


  info = '';


  constructor(
    private service: DbService) { }


  getEinstellungen(): void {
    this.service.getAll('einstellungen')
      .subscribe(einstellungen => this.einstellungen = einstellungen);
  }

  ngOnInit() {
    this.getEinstellungen();
    this.getDreh();
    this.getTypen();
    this.erase = [];
  }

  getEinstellung(art: string): Einstellung {
    return this.einstellungen.filter(x => x.art == art)[0];
  }


  getDreh(): void {
    this.service.getAll('dreh2')
      .subscribe(dreh => this.phasen = dreh);
  }

  getTypen(): void {
    this.service.getAll('typen')
      // tslint:disable-next-line:only-arrow-functions
      .subscribe(typen => this.typen = typen.sort(function(a, b) {return b.id - a.id}));
  }


  addZeile(): void {
    this.phasen.push({ id: this.count, name: ''});
    this.count--;

  }

  addZeile2(): void {
    this.typen.push({ id: -1, name: ''});

  }

  eraseZeile(id: number): void {
    // artid hinzufügen, um diese nachher in db zu löschen
    this.erase.push(id);
    this.phasen = this.phasen.filter(x => x.id !== id);
  }
  eraseZeile2(id: number): void {
    // artid hinzufügen, um diese nachher in db zu löschen
    this.typen = this.typen.filter(x => x.id !== id);
  }

  save(): void {
    this.service.updateAllWhere('phasen', this.phasen, 0, this.erase)
      .subscribe(
        () => {
          this.erase = [];
          this.getDreh();
        }
      );
    this.service.updateAllWhere('typen', this.typen, 0, this.erase)
      .subscribe(
        () => {
          this.getTypen();
        }
      );
    this.service.update('einstellungen', this.einstellungen)
      .subscribe(() => {
        this.info = 'Erfolgreich gespeichert';
        setTimeout(() => {
          this.info = '';
        }, 5000);
      });
  }

}
