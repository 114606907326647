<div class="bgimg"><div style="text-align: center;"><h2><strong>Projekte ändern und hinzufügen</strong></h2><br>

  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Name</strong></th>
    </thead>
    <tbody>
    <tr>
      <td><input id="name" type="text" [(ngModel)]="projekt.name"/></td>
    </tr>
    </tbody>
  </table><table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Ländercode</strong></th>
    </thead>
    <tbody>
    <tr>
      <td><mat-form-field>
        <mat-select placeholder="Wählen Sie ein Land" [(ngModel)]="projekt.laendercode" name="land">
          <mat-option *ngFor="let land of laendercode" [value]="land.name">
            {{land.name}}
          </mat-option>
        </mat-select>
      </mat-form-field></td>
    </tr>
    </tbody>
  </table><table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Markierer</strong></th>
    </thead>
    <tbody>
    <tr>
      <div *ngIf="currentUser.rechte<=2">
        <td><mat-form-field>
          <mat-select placeholder="Wählen Sie einen Markierer" [(ngModel)]="projekt.mid" name="marker">
            <mat-option *ngFor="let marker of markierer" [value]="marker.id">
              {{marker.name}}
            </mat-option>
          </mat-select>
        </mat-form-field></td>
      </div>
      <div *ngIf="currentUser.rechte>2">
        <td>{{this.projekt.markierer}}</td>
      </div>
    </tr>
    </tbody>
  </table><table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Gewässer</strong></th>
    </thead>
    <tbody>
    <tr>
      <td>
        <mat-form-field>
          <input matInput placeholder="Wählen Sie ein Gewässer" aria-label="Fluss" [(ngModel)]="fluss" (ngModelChange)="changeFluss()" [formControl]='fluesseControl' [matAutocomplete]="auto" >
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
            <ng-container *ngIf="!isLoading">
              <mat-option *ngFor="let fluss of filteredFluesse" [value]="fluss">
                <span>{{ fluss.name }}</span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
      </td>
    </tr>
    </tbody>
  </table><br><button (click)="addPosten()">Projekt hinzufügen</button><button (click)="goBack()">Zurück zum Hauptmenü</button>
  <br><br>

  Projekte filtern: <input type="text" [(ngModel)]="filter" (ngModelChange)="filterArten()"><br><br>
  <br><br>
  <strong>{{info}}</strong><br>

  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Name</strong></th>
    </thead>
    <tbody>
    <tr *ngFor="let projekt of projekte">
      <td><a routerLink="/besatzorte/{{projekt.id}}">{{projekt.name}} (Link zu Besatzorten)</a></td>
    </tr>
    </tbody>
  </table>
  <br>
</div></div>
