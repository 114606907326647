import { Component} from '@angular/core';
import { DbService } from '../_services/db.service';
import {GefundenComponent} from './gefunden.component';
import {AuthenticationService} from '../_services';

@Component({
  selector: 'app-gefunden',
  templateUrl: './gefunden.component.html',
  styleUrls: ['../home/home.component.css']
})
export class GefundenDesktopComponent extends GefundenComponent{

  constructor(
    service: DbService,
    authenticationService: AuthenticationService) {
    super(service, authenticationService);
  }

}
