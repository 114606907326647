
<div class="bgimg"><div style="text-align: center;"><h2><strong>Fisch: {{fisch.code | uppercase}}</strong></h2>
  <br><button (click)="goBack()">zurück zur Fischübersicht</button><br>
  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Fischart</strong></th>
    </thead>
    <tbody>
    <tr >
      <td>{{fisch.fisch}}</td>
    </tr>
    </tbody>
  </table>
  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Sender</strong></th>
    </thead>
    <tbody>
    <tr >
      <td>{{fisch.sender}}</td>
    </tr>
    </tbody>
  </table><table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Code</strong></th>
    </thead>
    <tbody>
    <tr >
      <td>{{fisch.code}}</td>
    </tr>
    </tbody>
  </table><table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Datum</strong></th>
    </thead>
    <tbody>
    <tr >
      <td>{{fisch.datum}}</td>
    </tr>
    </tbody>
  </table><table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Gewicht</strong></th>
    </thead>
    <tbody>
    <tr >
      <td>{{fisch.gewicht}}</td>
    </tr>
    </tbody>
  </table><table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Länge</strong></th>
    </thead>
    <tbody>
    <tr >
      <td>{{fisch.laenge}}</td>
    </tr>
    </tbody>
  </table><table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Projekt</strong></th>
    </thead>
    <tbody>
    <tr >
      <td><a routerLink="/besatzorte/{{projekt.id}}">{{projekt.name}}</a></td>
    </tr>
    </tbody>
  </table><table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Besatzort</strong></th>
    </thead>
    <tbody>
    <tr >
      <td><a routerLink="/besatzort/{{besatzort.id}}">{{besatzort.name}}</a></td>
    </tr>
    </tbody>
  </table><table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Gewässer</strong></th>
    </thead>
    <tbody>
    <tr >
      <td>{{projekt.fluss}}</td>
    </tr>
    </tbody>
  </table><table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Land</strong></th>
    </thead>
    <tbody>
    <tr >
      <td>{{projekt.laendercode}}</td>
    </tr>
    </tbody>
  </table><table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Markierer</strong></th>
    </thead>
    <tbody>
    <tr >
      <td>{{markierer.name}}</td>
    </tr>
    </tbody>
  </table>
  <br><br>

</div></div>
