import { Component} from '@angular/core';
import { DbService } from '../_services/db.service';
import {FischeComponent} from './fische.component';
import {AuthenticationService} from '../_services';

@Component({
  selector: 'app-fische',
  templateUrl: './fische.component.html',
  styleUrls: ['../home/home.component.css']
})
export class FischeDesktopComponent extends FischeComponent{

  constructor(
    service: DbService,
    authenticationService: AuthenticationService) {
    super(service, authenticationService);
  }

}
