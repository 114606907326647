import { OnInit, OnDestroy, Directive } from '@angular/core';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { User } from '../_models/user';
import { AuthenticationService } from '../_services';
import { DbService } from '../_services/db.service';
import {Gefunden} from '../_models/gefunden';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class HomeComponent implements OnInit, OnDestroy {
    currentUser: User;
  fische: Gefunden[];
  currentUserSubscription: Subscription;


    constructor(
        private authenticationService: AuthenticationService,
        private service: DbService
    ) {
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
        });
    }



  // tslint:disable-next-line:typedef
    ngOnInit() {
      this.getFische();
    }

  getFische(): void {
    if (this.currentUser.rechte > 3) {
      this.service.getAllWhereAnd('fund2', 'bearbeitet', 0, this.currentUser.markierer)
        .subscribe(projekte => this.fische = projekte);
    } else {
      this.service.getAllWhere('fund', 'bearbeitet', 0)
        .subscribe(projekte => this.fische = projekte);
    }
  }

  // tslint:disable-next-line:typedef
    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.currentUserSubscription.unsubscribe();
    }
}
