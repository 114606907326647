<div class="map-container">
  <div class="map-frame">
    <div id="map"></div>
  </div>

    <div class="bgimg2"><div style="text-align: center;">
      <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Name</strong></th>
      </thead>
      <tbody>
      <tr>
        <td><input id="name" type="text" [(ngModel)]="besatzort.name"/></td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Breitengrad / Latitude</strong></th><th><strong>Längengrad / Longitude</strong></th>
      </thead>
      <tbody>
      <tr><td>{{besatzort.lat}}</td>
        <td>{{besatzort.lon}}</td>
      </tr>
      </tbody>
    </table>
    <br><button (click)="addPosten()">Besatzort hinzufügen</button><button (click)="goBack()">Zurück zum Hauptmenü</button>

  <br><br><br><br><h2><strong>Besatzorte des Projekt {{projekt.name}} am Gewässer {{projekt.fluss}}</strong></h2> </div></div>
      <div class="bgimg3"><div style="text-align: center;">
    Besatzorte filtern:   <input type="text" [(ngModel)]="filter" (ngModelChange)="filterArten()"><br><br>
    <strong>{{info}}</strong><br>

    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Name</strong></th><th><strong>Fische</strong></th>
      <th><strong>Breitengrad / Latitude</strong></th><th><strong>Längengrad / Longitude</strong></th>
      </thead>
      <tbody>
      <tr *ngFor="let ort of besatzorte">
        <td><input id="art.name" type="text" [(ngModel)]="ort.name" (keyup.enter)="save(ort)"/></td>
        <td><a routerLink="/besatzort/{{ort.id}}">(Link zu den besetzen Fischen)</a></td>
        <td>{{ort.lat}}</td>
        <td>{{ort.lon}}</td>
      </tr>
      </tbody>
    </table>
        <br></div></div>
</div>
