
<div class="bgimg"><div style="text-align: center;"><h2><strong>Fische</strong></h2><br>
  Fische nach Code filtern:   <input type="text" [(ngModel)]="filter" (ngModelChange)="filterFische()"><br><br>
  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Code</strong></th>
    <th><strong>Fischart</strong></th><th><strong>Sender</strong></th><th><strong>Datum</strong></th>
    <th><strong>Länge</strong></th><th><strong>Gewicht</strong></th><th><strong>Besatzort</strong></th>
    <tbody>
    <tr *ngFor="let fisch of fische">
      <td><a routerLink="/fisch/{{fisch.id}}">{{fisch.code}}</a></td>
      <td>{{fisch.fisch}}</td>
      <td>{{fisch.sender}}</td>
      <td>{{fisch.datum | date:'dd.MM.yyyy'}}</td>
      <td>{{fisch.laenge}}</td>
      <td>{{fisch.gewicht}}</td>
      <td><a routerLink="/besatzort/{{fisch.bid}}">{{fisch.besatzort}}</a></td>
    </tr>
    </tbody>
  </table></div></div>
