import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { BehaviorSubject, Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import {User} from '../_models/user';
import {AuthenticationService} from './authentication.service';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'x-www-form-urlencoded' }),
};

@Injectable({
  providedIn: 'root',
})
export class DbService {
  public baseUrl = './api';
  public user: User;

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe(x => this.user = x);
  }

  public getAll(wanted: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/service.php`, { wanted, user: this.user }, httpOptions)
        .pipe(map((resp) => {
          if (resp.status === '200') {
            return resp.body;

          } else {
            throw new Error('Keine derartigen Daten vorhanden!');
          }

        }));
  }

    public getConcrete(wanted: string, id: number): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/service.php`, { wanted, id, user: this.user}, httpOptions)
            .pipe(map((resp) => {
                if (resp.status === '200') {
                    return resp.body[0];

                } else {
                    throw new Error('Keine derartigen Daten vorhanden!');
                }

            }));
    }

  public getCoords(hoch: number, rechts: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/coords.php`, { hoch: hoch, rechts: rechts}, httpOptions)
      .pipe(map((resp) => {
        if (resp.status === '200') {
          return resp.body;

        } else {
          throw new Error('Keine derartigen Daten vorhanden!');
        }

      }));
  }

    public insert(wanted: string, object2: any): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/insert.php`, { wanted, object2, user: this.user}, httpOptions)
            .pipe(map((resp) => {
                if (resp.status === '200') {
                    return resp.body;

                } else {
                    throw new Error('Keine derartigen Daten vorhanden!');
                }

            }));
    }

    public insertWith(wanted: string, object: any, object2: any): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/insert.php`, { wanted, object, object2, user: this.user}, httpOptions)
            .pipe(map((resp) => {
                if (resp.status === '200') {
                    return resp.body;

                } else {
                    throw new Error('Keine derartigen Daten vorhanden!');
                }

            }));
    }

    public getAllWhere(wanted: string, where: string, id: number): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/service.php`, { wanted, where, id, user: this.user }, httpOptions)
            .pipe(map((resp) => {
                if (resp.status === '200') {
                    return resp.body;

                } else {
                    throw new Error('Keine derartigen Daten vorhanden!');
                }

            }));
    }

    public getAllWhereAnd(wanted: string, where: string, id: number, mid: number): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/service.php`, { wanted, where, id, mid, user: this.user}, httpOptions)
            .pipe(map((resp) => {
                if (resp.status === '200') {
                    return resp.body;

                } else {
                    throw new Error('Keine derartigen Daten vorhanden!');
                }

            }));
    }

  public getAllWhereAnd2(wanted: string, where: string, von: string, bis: string, sid: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/service.php`,
      { wanted: wanted, where: where, von: von, bis: bis, sid: sid, user: this.user}, httpOptions)
      .pipe(map(resp => {
        if (resp.status === '200') {
          return resp.body;

        } else {
          throw new Error('Keine derartigen Daten vorhanden!');
        }



      }));
  }

    public getAllFilter(wanted: string, where: string, like: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/service.php`,
      { wanted: wanted, where: where, like: like, user: this.user }, httpOptions)
            .pipe(map((resp) => {
                if (resp.status === '200') {
                    return resp.body;

                } else {
                    throw new Error('Keine derartigen Daten vorhanden!');
                }

            }));
    }

    public getAllFilterAnd(wanted: string, where: string, like: string, sid: number, fid: number): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/service.php`,
          { wanted: wanted, where: where, like: like, sid: sid, fid: fid, user: this.user }, httpOptions)
            .pipe(map((resp) => {
                if (resp.status === '200') {
                    return resp.body;

                } else {
                    throw new Error('Keine derartigen Daten vorhanden!');
                }

            }));
    }

  public getAllFilterAnd2(wanted: string, where: string, like: string, sid: number, fid: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/service.php`,
      { wanted: wanted, where: where, like: like, sid: sid, fid: fid, user: this.user }, httpOptions)
      .pipe(map((resp) => {
        if (resp.status === '200') {
          return resp.body[0];

        } else {
          throw new Error('Keine derartigen Daten vorhanden!');
        }

      }));
  }

  update(wanted: string, object: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/update.php`, { wanted: wanted, object: object, user: this.user}, httpOptions)
      .pipe(map(resp => {
        if (resp.status === '200') {
          return resp.body;

        } else {
          throw new Error('Keine derartigen Daten vorhanden!');
        }



      }));
  }

  updateWith(wanted: string, object: any, object2: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/update.php`, { wanted, object, object2, user: this.user}, httpOptions)
      .pipe(map((resp) => {
        if (resp.status === '200') {
          return resp.body;

        } else {
          throw new Error('Keine derartigen Daten vorhanden!');
        }

      }));
  }

  updateAllWhere(wanted: string, object: any[], id: number, erase: number[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/update.php`, { wanted: wanted , object: object, id: id, erase: erase, user: this.user},  httpOptions)
      .pipe(map(resp => {
        if (resp.status === '200') {
          return resp.body;

        } else {
          throw new Error('Keine derartigen Daten vorhanden!');
        }



      }));
  }
}
