import { Component, OnInit } from '@angular/core';
import { DbService } from '../_services/db.service';
import {EinstellungenComponent} from './einstellungen.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'einstellungen-desktop-app',
  templateUrl: './einstellungen.component.html',
  styleUrls: ['../home/home.component.css']
})

export class EinstellungenDesktopComponent extends  EinstellungenComponent{

  constructor(
    service: DbService) {super(service); }

}
