<div class="bgimg"><div style="text-align: center;"><h2><strong>Gewässer ändern und hinzufügen</strong></h2><br>

  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Gewässerkennzahl</strong></th>
    </thead>
    <tbody>
    <tr>
      <td><input id="id" type="number" [(ngModel)]="art.id"/></td>
    </tr>
    </tbody>
  </table>
  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Name</strong></th>
    </thead>
    <tbody>
    <tr>
      <td><input id="name" type="text" [(ngModel)]="art.name"/></td>
    </tr>
    </tbody>
  </table><br><button (click)="addPosten()">Fluss hinzufügen</button><button (click)="goBack()">Zurück zum Hauptmenü</button>
  <br><br>

  Flüsse filtern:   <input type="text" [(ngModel)]="filter" (ngModelChange)="filterArten()"><br><br>
  <br><br>
  <strong>{{info}}</strong><br>

  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Gewässerkennzahl</strong></th>
    <th><strong>Name</strong></th>
    </thead>
    <tbody>
    <tr *ngFor="let fluss of arten">
      <td>{{fluss.id}}</td>
      <td><input id="art.name" type="text" [(ngModel)]="fluss.name" (keyup.enter)="save(fluss)"/></td>
    </tr>
    </tbody>
  </table>
  <br>
</div></div>
