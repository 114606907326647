import {OnInit, Directive, AfterViewInit} from '@angular/core';
import { DbService } from '../_services/db.service';
import {Fisch} from '../_models/fisch';
import {Besatzort} from '../_models/besatzort';
import {ActivatedRoute, Router} from '@angular/router';
import {formatDate} from '@angular/common';
import {Auswahl} from '../_models/auswahl';
import * as L from 'leaflet';
import {debounceTime, finalize, switchMap, tap} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../_services';
import {User} from '../_models/user';
import {Auswahl2} from '../_models/auswahl2';



@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class BesatzortComponent implements OnInit, AfterViewInit {

  filteredFische: Auswahl2[];
  filteredCode: Fisch[];
  code: Fisch;
  fisch: Auswahl2;
  filter = '';
  besatzort: Besatzort;
  senders: Auswahl2[];
  zeit = '00:00:00';
  info = '';

  map;
  fischeControl = new FormControl();
  isLoading = false;
  fishIcon;
  currentUser: User;
  constructor(private router: Router,
              private service: DbService,
              private route: ActivatedRoute,
              private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }


  getBesatzort(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.service.getConcrete('besatzort', id)
      .subscribe(projekt => this.besatzort = projekt);
  }

  getSender(): void {
    this.service.getAll('senders')
      .subscribe(projekt => this.senders = projekt);
  }

  filterArten(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.service.getAllFilterAnd('fischebo', 'code', this.filter, id, 0)
      .subscribe(projekte => this.filteredCode = projekte);
  }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('id');
    if (this.currentUser.rechte > 3) {
      this.service.getConcrete('besatzort', id)
        .subscribe(projekt => {
          this.service.getConcrete('projekt', projekt.id)
            .subscribe(proj => {
              {
                if (this.currentUser.markierer !== proj.mid){
                  this.router.navigate(['/']);
                }
              }
            });
        });
    }
    this.getBesatzort();
    this.filterArten();
    this.getSender();
    this.code = { id: 0, code: '', fid: 0, fisch: '', sid: 0, sender: '', bid: id, besatzort: '',
      datum: formatDate(new Date(), 'yyyy_MM_dd', 'en'), laenge: 0, gewicht: 0};

    this.fischeControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredFische = [];
          this.isLoading = true;
        }),
        switchMap(value => this.obsFische(value)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
          )
        )
      )
      .subscribe(
        data => this.filteredFische = data
      );

  }

  obsFische(value: string): Observable<Auswahl2[]> {
    return this.service.getAllFilter('fisch', 'name', value);
  }

  addPosten(): void {
    this.code.datum = this.code.datum + ' ' + this.zeit;
    this.service.insert('fisch', this.code).subscribe(
      () => {
        const id = +this.route.snapshot.paramMap.get('id');
        this.code = { id: 0, code: '', fid: 0, fisch: '', sid: 0, sender: '', bid: id, besatzort: '',
          datum: formatDate(new Date(), 'yyyy_MM_dd', 'en'), laenge: 0, gewicht: 0};
        this.zeit = '00:00:00';
        this.filterArten();
        this.info = 'Erfolgreich gespeichert';
        setTimeout( () => { this.info = ''; }, 5000 );
      }
    );

  }

  erase(id: number): void {
    this.service.update('delfisch', id).subscribe(
      () => {
        this.filteredCode = this.filteredCode.filter(x => x.id !== id);
      }
    );
  }

  initMap(): void {
    this.map = L.map('map', {
          center: [ this.besatzort.lat, this.besatzort.lon ],
          zoom: 13,
        });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          // tslint:disable-next-line:object-literal-sort-keys
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        });

    this.fishIcon = L.icon({
          iconUrl: 'FishTrek.png',
          shadowUrl: 'schatten.png',

          iconSize:     [50, 50], // size of the icon
          shadowSize:   [2, 2], // size of the shadow
          iconAnchor:   [10, 10], // point of the icon which will correspond to marker's location
          shadowAnchor: [4, 52],  // the same for the shadow
          popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
        });

    tiles.addTo(this.map);
    L.marker([this.besatzort.lat, this.besatzort.lon], {icon: this.fishIcon}).addTo(this.map);

  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit(): void {
    this.initMap();
  }

  goBack(): void {
    this.router.navigate(['/']);
  }
/*
  save(fisch: Fisch): void {
    this.service.update('fisch', fisch)
      .subscribe(
        () => {
          this.filterArten();
          this.info = 'Erfolgreich gespeichert';
          setTimeout( () => { this.info = ''; }, 5000 );
        }
      );
  }
*/
  // tslint:disable-next-line:typedef
  displayFn(kunde: Auswahl) {
    if (kunde) {
      return kunde.name; }
  }

  changeFisch(): void {
    this.code.fid = this.fisch.id;
  }

}
