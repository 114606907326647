<div class="map-container">
  <div class="map-frame2">
    <div id="map"></div>
  </div>

  <div class="bgimg4"><div style="text-align: center;"><h2><strong>Fisch: {{fisch.code | uppercase}}</strong></h2>
  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Fischart</strong></th><th><strong>Sendertyp</strong></th>
    <th><strong>Code</strong></th><th><strong>Aussatzdatum</strong></th><th><strong>Gewicht</strong></th>
    <th><strong>Länge</strong></th>
    </thead>
    <tbody>
    <tr >
      <td>{{fisch.fisch}}</td>
      <td>{{fisch.sender}}</td>
      <td><input type="text" [(ngModel)]="fisch.code"></td>
      <td>{{fisch.datum| date:'dd.MM.yyyy'}}</td>
      <td><input type="number" [(ngModel)]="fisch.gewicht"> g</td>
      <td><input type="number" [(ngModel)]="fisch.laenge"> cm</td>
    </tr>
    </tbody>
  </table>
  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead><th><strong>Projekt</strong></th>
    <th><strong>Besatzort</strong></th><th><strong>Gewässer</strong></th><th><strong>Land</strong></th>
    <th><strong>Markierer</strong></th>
    </thead>
    <tbody>
    <tr>
      <td><a routerLink="/besatzorte/{{projekt.id}}">{{projekt.name}}</a></td>
      <td><a routerLink="/besatzort/{{besatzort.id}}">{{besatzort.name}}</a></td>
      <td>{{projekt.fluss}}</td>
      <td>{{projekt.laendercode}}</td>
      <td>{{markierer.name}}</td>
    </tr>
    </tbody>
  </table>
    <br><br><button (click)="save()">Änderungen speichern</button>
  <br><br>
    <strong>{{info}}</strong><br><br>
  </div></div>
</div>
