import {Component, OnInit, Directive, AfterViewInit} from '@angular/core';
import { Projekt } from '../_models/projekt';
import { DbService } from '../_services/db.service';
import { Router, ActivatedRoute } from '@angular/router';
import {formatDate, Location} from '@angular/common';
import {Fisch} from '../_models/fisch';
import {Besatzort} from '../_models/besatzort';
import * as L from 'leaflet';
import {AuthenticationService} from '../_services';
import {User} from '../_models/user';
import {Markierer} from '../_models/markierer';



@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class FischComponent implements OnInit, AfterViewInit {
  fisch: Fisch;
  projekt: Projekt;
  besatzort: Besatzort;
  markierer: Markierer;
  map;
  fishIcon;
  currentUser: User;
  info = '';

  constructor(
      private router: Router,
      private service: DbService,
      private route: ActivatedRoute,
      private location: Location,
      private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  initMap(): void {
    this.map = L.map('map', {
        center: [ this.besatzort.lat, this.besatzort.lon],
        zoom: 13,
      });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          // tslint:disable-next-line:object-literal-sort-keys
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        });

    tiles.addTo(this.map);

    this.fishIcon = L.icon({
          iconUrl: 'FishTrek.png',
          shadowUrl: 'schatten.png',

          iconSize:     [50, 50], // size of the icon
          shadowSize:   [2, 2], // size of the shadow
          iconAnchor:   [10, 10], // point of the icon which will correspond to marker's location
          shadowAnchor: [4, 52],  // the same for the shadow
          popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
        });

    tiles.addTo(this.map);
    L.marker([this.besatzort.lat, this.besatzort.lon], {icon: this.fishIcon}).addTo(this.map);
  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit(): void {
    this.initMap();
  }

  getFisch(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.service.getConcrete('fischbyid', id)
        .subscribe(fisch => {
          this.fisch = fisch;
          this.service.getConcrete('besatzort', fisch.bid)
            .subscribe(besatzort => {
              this.besatzort = besatzort;
              this.service.getConcrete('projekt', besatzort.pid)
                .subscribe(projekt => {
                  this.projekt = projekt;
                  this.service.getConcrete('markierer', projekt.mid)
                    .subscribe(markierer => this.markierer = markierer);
                } );
            });
        });
  }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    if (this.currentUser.rechte > 3) {
      const id = +this.route.snapshot.paramMap.get('id');
      this.service.getConcrete('fischbyid', id)
        .subscribe(fisch => {
          this.service.getConcrete('besatzort', fisch.id)
            .subscribe(projekt => {
              this.service.getConcrete('projekt', projekt.id)
                .subscribe(proj => {
                  {
                    if (this.currentUser.markierer !== proj.mid) {
                      this.router.navigate(['/']);
                    }
                  }
                });
            });
        });
    }
    this.getFisch();
  }

  save(): void {
    this.service.update('fisch', this.fisch).subscribe(
      () => {
        this.getFisch();
        this.info = 'Erfolgreich gespeichert';
        setTimeout( () => { this.info = ''; }, 5000 );
      }
    );

  }

  goBack(): void {
    this.router.navigate(['/fische']);
  }
}
