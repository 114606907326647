import { Component} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder} from '@angular/forms';


import { AlertService, AuthenticationService } from '../_services';
import {LoginComponent} from '../login/login.component';

@Component({templateUrl: './login.component.mobile.html',   styleUrls: ['./login.component.mobile.css']})
export class LoginMobileComponent extends LoginComponent {
  constructor(formBuilder: FormBuilder,
              route: ActivatedRoute,
              router: Router,
              authenticationService: AuthenticationService,
              alertService: AlertService) {
    super(formBuilder, route, router, authenticationService, alertService);
  }
}
