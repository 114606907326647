<div class="bgimg"><div style="text-align: center;"><h2><strong>gefangener Fisch vom {{fund.datum | date:'dd.MM.yyyy'}}</strong></h2><br>

  <div class="map-frame2">
    <div id="map"></div>
  </div>

  <div class="bgimg3">
    <h2><strong>aktuelle Informationen zum gefangenen Fisch</strong></h2>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Fischart</strong></th>
      <tbody>
      <tr>
        <td>{{fund.fisch}}</td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
     <th><strong>Sender</strong></th>
      </thead>
      <tbody>
      <tr>
        <td>{{fund.sender}}</td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead><th><strong>Gewässer</strong></th><th>
      </thead>
      <tbody>
      <tr>
        <div *ngIf="fund.fid==0">
          <td>
            <mat-form-field>
              <input matInput placeholder="Wählen Sie einen Fluss" aria-label="Fluss" [(ngModel)]="fluss" (ngModelChange)="changeFluss()" [formControl]='fluesseControl' [matAutocomplete]="auto" >
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                <ng-container *ngIf="!isLoading">
                  <mat-option *ngFor="let fluss of filteredFluesse" [value]="fluss">
                    <span>{{ fluss.name }}</span>
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>
          </td>
        </div>
        <div *ngIf="fund.fid!=0">
          <td>{{fund.fluss}}</td>
        </div>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Länge</strong></th>
      <tbody>
      <tr>
        <td>{{fund.laenge}}</td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead><th><strong>Gewicht</strong></th>
      </thead>
      <tbody>
      <tr>
        <td>{{fund.gewicht}}</td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><th><strong>Datum</strong></th>
      </thead>
      <tbody>
      <tr>
        <td>{{fund.datum | date:'dd.MM.yyyy'}}</td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Notiz</strong></th><th>
      </thead>
      <tbody>
      <tr>
        <td><textarea id="fund.notiz" cols="40" rows="4"  [(ngModel)]="fund.notiz"></textarea></td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
     <th><strong>Status</strong></th>
      </thead>
      <tbody>
      <tr>
        <td><mat-form-field>
          <mat-select placeholder="Wählen Sie den Status" [(ngModel)]="fund.bearbeitet" name="send">
            <mat-option *ngFor="let stat of status" [value]="stat.id">
              {{stat.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        </td>
      </tr>
      </tbody>
    </table>
    <br><button (click)="save()">Änderungen speichern</button><button (click)="goBack()">Zurück zum Hauptmenü</button>
  </div>
  <br><br>
  <div class="bgimg3">
    <div *ngIf="fund.fischid==0">
      Der Code konnte von Finder nicht identifiziert werden. Bitte wählen Sie den Code aus.<br>
      <mat-form-field>
        <input matInput placeholder="Wählen Sie den Code" aria-label="Code" [(ngModel)]="code" (ngModelChange)="changeCode()" [formControl]='senderControl' [matAutocomplete]="auto3" >
        <mat-autocomplete #auto3="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
          <ng-container *ngIf="!isLoading">
            <mat-option *ngFor="let fisch of filteredCode" [value]="fisch">
              <span>{{ fisch.name }}</span>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div *ngIf="fund.fischid>0">
      <h2><strong>Informationen zum ursprünglich besetztem Fisch</strong></h2>
      <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
        <thead>
        <th><strong>Fischart</strong></th>
        </thead>
        <tbody>
        <tr >
          <td>{{fisch.fisch}}</td>
        </tr>
        </tbody>
      </table>
      <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
        <thead>
        <th><strong>Sender</strong></th>
        </thead>
        <tbody>
        <tr >
          <td>{{fisch.sender}}</td>
        </tr>
        </tbody>
      </table><table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Code</strong></th>
      </thead>
      <tbody>
      <tr >
        <td>{{fisch.code}}</td>
      </tr>
      </tbody>
    </table><table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Datum</strong></th>
      </thead>
      <tbody>
      <tr >
        <td>{{fisch.datum}}</td>
      </tr>
      </tbody>
    </table><table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Gewicht</strong></th>
      </thead>
      <tbody>
      <tr >
        <td>{{fisch.gewicht}}</td>
      </tr>
      </tbody>
    </table><table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Länge</strong></th>
      </thead>
      <tbody>
      <tr >
        <td>{{fisch.laenge}}</td>
      </tr>
      </tbody>
    </table><table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Projekt</strong></th>
      </thead>
      <tbody>
      <tr >
        <td><a routerLink="/projekt/{{projekt.id}}">{{projekt.name}}</a></td>
      </tr>
      </tbody>
    </table><table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Besatzort</strong></th>
      </thead>
      <tbody>
      <tr >
        <td><a routerLink="/projekt/{{besatzort.id}}">{{besatzort.name}}</a></td>
      </tr>
      </tbody>
    </table><table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Gewässer</strong></th>
      </thead>
      <tbody>
      <tr >
        <td>{{projekt.fluss}}</td>
      </tr>
      </tbody>
    </table><table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Land</strong></th>
      </thead>
      <tbody>
      <tr >
        <td>{{projekt.laendercode}}</td>
      </tr>
      </tbody>
    </table><table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Markierer</strong></th>
      </thead>
      <tbody>
      <tr >
        <td><a routerLink="/projekt/{{markierer.id}}">{{markierer.name}}</a></td>
      </tr>
      </tbody>
    </table>
    </div>
    <br>
    <h2><strong>Informationen zum Finder</strong></h2>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Nachname</strong></th>
      </thead>
      <tbody>
      <tr>
        <td>{{finder.name}}</td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Vorname</strong></th>
      </thead>
      <tbody>
      <tr>
        <td>{{finder.vorname}}></td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Straße</strong></th>
      </thead>
      <tbody>
      <tr>
        <td>{{finder.strasse}}</td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead><th><strong>PLZ</strong></th>
      </thead>
      <tbody>
      <tr>
        <td>{{finder.plz}}</td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Ort</strong></th>
      </thead>
      <tbody>
      <tr>
        <td>{{finder.ort}}</td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Telefon</strong></th>
      </thead>
      <tbody>
      <tr>
        <td>{{finder.telefon}}</td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>E-Mail</strong></th>
      </thead>
      <tbody>
      <tr>
        <td>{{finder.email}}</td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead><th><strong>IBAN</strong></th>
      </thead>
      <tbody>
      <tr>
        <td>{{finder.iban}}</td>
      </tr>
      </tbody>
    </table><br><br>
  </div></div>
</div>
