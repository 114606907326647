import { Component, OnInit, OnDestroy } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';

import { User } from '../_models/user';
import { AuthenticationService } from '../_services';
import {DbService} from '../_services/db.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {Auswahl} from '../_models/auswahl';
import {Markierer} from '../_models/markierer';
import {Auswahl2} from '../_models/auswahl2';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['../home/home.component.css']
})
export class UserComponent implements OnInit {

  currentUser: User;
  currentUserSubscription: Subscription;
  newUser: User;
  user: User[];
  heute: string = formatDate(new Date(), 'yyyy_MM_dd', 'en');
  count = -1;
  erase: number[] = [];
  rechte: Auswahl2[];
  recht: Auswahl2;
  markierer: Markierer;
  markers: Markierer[];

  usersForm: FormGroup;
  isLoading = false;




  constructor(
      private fb: FormBuilder,
      private router: Router,
      private service: DbService,
      private route: ActivatedRoute,
      private location: Location,
      private authenticationService: AuthenticationService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    if (this.currentUser.rechte > 1) {
      this.router.navigate(['/']);
    }

    this.getUser();
    this.getRechte();
    this.getMarkierer();

    this.newUser = { id: this.count, mname: '',  nik: '',  password: '', pw: '',  rechte: 0, markierer: 6};
    this.count--;

  }
  getUser(): void {
    this.service.getAll('user')
        .subscribe(team => this.user = team);
  }

  getMarkierer(): void {
    this.service.getAll('markierers').subscribe(
      markierers => this.markers = markierers
    );
  }

  getRechte(): void {
    this.service.getAll('rechte')
        .subscribe(rechte => this.rechte = rechte);
  }

  addUser(): void {
    this.newUser.rechte = this.recht.id;
    this.newUser.markierer = this.markierer.id;
    this.user.push(this.newUser);
    this.newUser = { id: this.count, mname: '',  nik: '',  password: '', pw: '',  rechte: 0, markierer: 6};
    this.count--;
  }

  save(): void {
    this.service.updateAllWhere('user', this.user, this.currentUser.id, this.erase)
        .subscribe(() => this.getUser());
  }

  eraseZeile(id: number): void {
    // artid hinzufügen, um diese nachher in db zu löschen
    this.erase.push(id);
    this.user = this.user.filter(x => x.id !== id);
  }

  // tslint:disable-next-line:typedef use-lifecycle-interface
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.currentUserSubscription.unsubscribe();
  }

}
