import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { BehaviorSubject, Observable} from 'rxjs';
import { AlertService } from '../_services';
import { map } from 'rxjs/operators';

import { User } from '../_models/user';
import {Router} from "@angular/router";

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'x-www-form-urlencoded' })
};

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    baseUrl = './api';
    private alertService: AlertService;

    constructor(private router: Router,
                private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }


  // tslint:disable-next-line:typedef
    login(username: string, password: string) {
        return this.http.post<any>(`${this.baseUrl}/auto.php`, { username: username, password: password }, httpOptions)
            .pipe(map(resp => {
                // console.log(user.body.username);
                // login successful if there's a jwt token in the response
                if ((resp.body.password) && (resp.status === '200')) {

                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(resp.body));
                    this.currentUserSubject.next(resp.body);
                    return resp;

                } else {
                    throw new Error('Kein derartiger User vorhanden!');
                }



            }));
    }

  // tslint:disable-next-line:typedef
logout() {
      this.http.post<any>(`${this.baseUrl}/update.php`,
        { wanted: 'session', id: this.currentUserValue.id, user: this.currentUserValue},  httpOptions).subscribe( () => {
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.router.navigate(['/login']);
      });
        // remove user from local storage to log user out

    }
}
