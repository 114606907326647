<div class="bgimg"><div style="text-align: center;"><h2><strong>Fischarten ändern und hinzufügen</strong></h2><br>

  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Name</strong></th><th><strong>Name (englisch)</strong></th>
    </thead>
    <tbody>
    <tr>
      <td><input id="name" type="text" [(ngModel)]="art.name"/></td>
      <td><input id="nameeng" type="text" [(ngModel)]="art.nameeng"/></td>
    </tr>
    </tbody>
  </table><br><button (click)="addPosten()">Fischart hinzufügen</button><button (click)="goBack()">Zurück zum Hauptmenü</button>
  <br><br>

  Fischarten filtern:   <input type="text" [(ngModel)]="filter" (ngModelChange)="filterArten()"><br><br>
  <br><br>
  <strong>{{info}}</strong><br>

  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Name</strong></th><th><strong>Name (englisch)</strong></th>
    </thead>
    <tbody>
    <tr *ngFor="let fisch of arten">
      <td><input id="art.name" type="text" [(ngModel)]="fisch.name" (keyup.enter)="save(fisch)"/></td>
      <td><input id="art.nameeng" type="text" [(ngModel)]="fisch.nameeng" (keyup.enter)="save(fisch)"/></td>
    </tr>
    </tbody>
  </table>
  <br>
</div></div>
