
<div class="bgimg"><div style="text-align: center;"><h2><strong>Fische</strong></h2><br>
  Fische nach Code filtern:   <input type="text" [(ngModel)]="filter" (ngModelChange)="filterFische()"><br><br>
  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Code</strong></th>
    <tbody>
    <tr *ngFor="let fisch of fische">
      <td><a routerLink="/fisch/{{fisch.id}}">{{fisch.code}}</a></td>
    </tr>
    </tbody>
  </table></div></div>
