<div class="bgimg"><div style="text-align: center;"><h2><strong>Besatzorte des Projekt {{projekt.name}} am Fluss {{projekt.fluss}}</strong></h2><br>

  <div class="map-frame2">
    <div id="map"></div>
  </div>

  <div class="bgimg4"><div style="text-align: center;">
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Name</strong></th>
      </thead>
      <tbody>
      <tr>
        <td><input id="name" type="text" [(ngModel)]="besatzort.name"/></td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Breitengrad / Latitude</strong></th><th><strong>Längengrad / Longitude</strong></th>
      </thead>
      <tbody>
      <tr><td>{{besatzort.lat}}</td>
        <td>{{besatzort.lon}}</td>
      </tr>
      </tbody>
    </table>
    <br><button (click)="addPosten()">Besatzort hinzufügen</button><button (click)="goBack()">Zurück zum Hauptmenü</button>
  </div></div>
  <br><br>
  <div class="bgimg5"><div style="text-align: center;">
    Besatzorte filtern:   <input type="text" [(ngModel)]="filter" (ngModelChange)="filterArten()"><br><br>
    <br><br>
    <strong>{{info}}</strong><br>
    (Link zu besetzen Fischen)
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Name</strong></th>
      </thead>
      <tbody>
      <tr *ngFor="let ort of besatzorte">
        <td><a routerLink="/besatzort/{{besatzort.id}}">{{besatzort.name}}</a></td>
      </tr>
      </tbody>
    </table>
    <br></div></div>
</div></div>
