import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { routing } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {LoginDesktopComponent} from './login/login.component.desktop';
import {LoginMobileComponent} from './login/login.component.mobile';
import {AlertComponent} from './_components';
import {EinstellungenDesktopComponent} from './einstellungen/einstellungen.component.desktop';
import {EinstellungenMobileComponent} from './einstellungen/einstellungen.component.mobile';
import {FischeDesktopComponent} from './fische/fische.component.desktop';
import {FischeMobileComponent} from './fische/fische.component.mobile';
import {FischDesktopComponent} from './fisch/fisch.component.desktop';
import {HomeDesktopComponent} from './home/home.component.desktop';
import {HomeMobileComponent} from './home/home.component.mobile';
import {UserComponent} from './user/user.component';
import {FischMobileComponent} from './fisch/fisch.component.mobile';
import {FischartenComponent} from './fischarten/fischarten.component';
import {FluesseComponent} from './fluesse/fluesse.component';
import {ProjekteDesktopComponent} from './projekte/projekte.component.desktop';
import {ProjekteMobileComponent} from './projekte/projekte.component.mobile';
import {BesatzorteMobileComponent} from './besatzorte/besatzorte.component.mobile';
import {BesatzorteDesktopComponent} from './besatzorte/besatzorte.component.desktop';
import {BesatzortDesktopComponent} from './besatzort/besatzort.component.desktop';
import {BesatzortMobileComponent} from './besatzort/besatzort.component.mobile';
import {GefundenDesktopComponent} from './gefunden/gefunden.component.desktop';
import {GefundenMobileComponent} from './gefunden/gefunden.component.mobile';
import {FundDesktopComponent} from './fund/fund.component.desktop';
import {FundMobileComponent} from './fund/fund.component.mobile';
import {MarkiererDesktopComponent} from './markierer/markierer.component.desktop';

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    LoginDesktopComponent,
    LoginMobileComponent,
    EinstellungenDesktopComponent,
    EinstellungenMobileComponent,
    FischeDesktopComponent,
    FischeMobileComponent,
    FischDesktopComponent,
    FischMobileComponent,
    FischartenComponent,
    FluesseComponent,
    HomeDesktopComponent,
    HomeMobileComponent,
    UserComponent,
    ProjekteDesktopComponent,
    ProjekteMobileComponent,
    BesatzorteMobileComponent,
    BesatzorteDesktopComponent,
    BesatzortDesktopComponent,
    BesatzortMobileComponent,
    GefundenDesktopComponent,
    GefundenMobileComponent,
    FundDesktopComponent,
    FundMobileComponent,
    MarkiererDesktopComponent
  ],
  imports: [
    BrowserModule,
    routing,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatSelectModule
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

