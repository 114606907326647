import {Component} from '@angular/core';
import { DbService } from '../_services/db.service'
import {BesatzorteComponent} from './besatzorte.component';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../_services';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'besatzorte-app',
  templateUrl: './besatzorte.component.html',
  styleUrls: ['../app.component.css']})

export class BesatzorteDesktopComponent extends BesatzorteComponent{

  constructor(
    router: Router,
    service: DbService,
    route: ActivatedRoute,
    authenticationService: AuthenticationService) {
    super(router, service, route, authenticationService);
  }

}
