import {OnInit, Directive, AfterViewInit} from '@angular/core';
import { DbService } from '../_services/db.service';
import {Fisch} from '../_models/fisch';
import {Besatzort} from '../_models/besatzort';
import {ActivatedRoute, Router} from '@angular/router';
import {formatDate} from '@angular/common';
import {Auswahl} from '../_models/auswahl';
import * as L from 'leaflet';
import {debounceTime, finalize, switchMap, tap} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {Gefunden} from '../_models/gefunden';
import {Finder} from '../_models/finder';
import {Projekt} from '../_models/projekt';
import {GeoSearchControl, OpenStreetMapProvider} from 'leaflet-geosearch';




@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class FundComponent implements OnInit, AfterViewInit {

  fund: Gefunden;
  fisch: Fisch;
  finder: Finder;
  besatzort: Besatzort;
  projekt: Projekt;
  markierer: Auswahl;
  status: Auswahl[];
  fluss: Auswahl;
  code: Auswahl;
  filteredCode: Auswahl[];
  fluesseControl = new FormControl();
  senderControl = new FormControl();
  finderControl = new FormControl();
  isLoading = false;
  map;
  info = '';
  filteredFluesse: Auswahl[];
  fishIcon;
  layers;
  tag = '';
  show = false;
  filteredFinder: Finder[] = [];

  constructor(private router: Router,
              private service: DbService,
              private route: ActivatedRoute) { }

  initMap(): void {
    this.map = L.map('map', {
          center: [ this.fund.xcord, this.fund.ycord ],
          zoom: 6,
        });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          // tslint:disable-next-line:object-literal-sort-keys
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        });

    tiles.addTo(this.map);

    this.fishIcon = L.icon({
          iconUrl: 'FishTrek.png',
          shadowUrl: 'schatten.png',

          iconSize:     [50, 50], // size of the icon
          shadowSize:   [2, 2], // size of the shadow
          iconAnchor:   [20, 20], // point of the icon which will correspond to marker's location
          shadowAnchor: [4, 52],  // the same for the shadow
          popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
        });

    tiles.addTo(this.map);

    const provider = new OpenStreetMapProvider();

    const searchControl = GeoSearchControl({
      provider,
      showMarker: false, // optional: true|false  - default true
      showPopup: false,
    });

    this.map.addControl(searchControl);

    this.layers = L.layerGroup();
    if (this.fund.xcord !== 0) {
      this.layers.addLayer(L.marker([this.fund.xcord, this.fund.ycord], {icon: this.fishIcon}).addTo(this.map));
    }
    this.map.addLayer(this.layers);


    this.map.on('click', <LeafletMouseEvent>(e) => {
      this.fund.xcord = e.latlng.lat;
      this.fund.ycord = e.latlng.lng;
      this.layers.clearLayers();
      this.layers.addLayer(L.marker([e.latlng.lat, e.latlng.lng], {icon: this.fishIcon}));
    });

   /* if (this.fund.xcord !== 0){
      L.marker([this.fund.xcord, this.fund.ycord], {icon: this.fishIcon}).addTo(this.map);
    }
*/
  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit(): void {
    this.initMap();
  }


  getFisch(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.service.getConcrete('fundbyid', id)
      .subscribe(fund => {
        this.fund = fund;
        // this.tag = fund.datum.getDate() + '-' + (fund.datum.getMonth() + 1) + '-' + fund.datum.getFullYear();
        if (fund.findid > 0){
          this.service.getConcrete('finder', fund.findid)
            .subscribe(finder => this.finder = finder);
        } else {
          this.finder = { id: 0, name: '', vorname: '', strasse: '', plz: '', ort: '', telefon: '',
            email: '', iban: '', bank: '', bic: '' };
        }
        if (fund.fischid > 0){
          this.service.getConcrete('fischbyid', fund.fischid)
            .subscribe(fisch => {
              this.fisch = fisch;
              this.service.getConcrete('besatzort', fisch.bid)
                .subscribe(besatzort => {
                  this.besatzort = besatzort;
                  this.service.getConcrete('projekt', besatzort.pid)
                    .subscribe(projekt => {
                      this.projekt = projekt;
                      this.service.getConcrete('markierer', projekt.mid)
                        .subscribe(markierer => this.markierer = markierer);
                    } );
                });
            });
        }else{
          this.fisch = this.besatzort = this.projekt = this.markierer = null;
        }
      }
    );
  }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    this.getFisch();
    this.service.getAll('status').subscribe(staten => this.status = staten);
    this.fluss = this.code = {id: 0, name: ''};

    this.fluesseControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredFluesse = [];
          this.isLoading = true;
        }),
        switchMap(value => this.obsFluesse(value)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
          )
        )
      )
      .subscribe(
        data => this.filteredFluesse = data
      );

    this.senderControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredCode = [];
          this.isLoading = true;
        }),
        switchMap(value => this.obsCode(value)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
          )
        )
      )
      .subscribe(
        data => this.filteredCode = data
      );

    this.finderControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredFinder = [];
          this.isLoading = true;
        }),
        switchMap(value => this.obsFinder(value).pipe(
            finalize(() => {
              this.isLoading = false;
            }),
          )
        )
      )
      .subscribe(
        data => this.filteredFinder = data
      );

  }

  obsFluesse(value: string): Observable<Auswahl[]> {
    return this.service.getAllFilter('fluss', 'name', value);
  }

  obsFinder(value: string): Observable<Finder[]> {
    return this.service.getAllFilter('findfinder', 'name', value);
  }

  obsCode(value: string): Observable<Auswahl[]> {
    return this.service.getAllFilterAnd('code', 'code', value, this.fund.sid, this.fund.fid);
  }

  changeFluss(): void {
    this.fund.flid = this.fluss.id;
    this.service.update('fund', this.fund)
      .subscribe(() => this.getFisch());
  }

  changeCode(): void {
    this.fund.fischid = this.code.id;
    this.service.update('fund', this.fund)
      .subscribe(() => this.getFisch());
  }

  save(): void {
    if (this.fund.findid === 0){
      this.service.updateWith('insFinder', this.finder, this.fund)
        .subscribe(() => {
          this.info = 'Erfolgreich gespeichert';
          setTimeout( () => { this.info = '';  this.getFisch(); }, 5000 );
        });
    } else {
      this.service.update('fund', this.fund)
        .subscribe(
          () => {
            this.service.update('finder', this.finder);
            // this.getFisch();
            this.info = 'Erfolgreich gespeichert';
            setTimeout( () => { this.info = ''; this.getFisch(); }, 5000 );
          }
        );
    }
  }

  toggleShow(): void {
    this.show = !this.show;
  }

  displayFn(find: Finder): string {
    if (find) {
      return find.name; }
  }

reloadFinder(): void {
  this.fund.findid = this.finder.id;
}

  goBack(): void {
    this.router.navigate(['/']);
  }
}
