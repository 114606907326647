import { Component} from '@angular/core';
import { DbService } from '../_services/db.service';
import {BesatzortComponent} from './besatzort.component';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../_services';

@Component({
  selector: 'app-besatzort',
  templateUrl: './besatzort.component.html',
  styleUrls: ['../app.component.css']
})
export class BesatzortDesktopComponent extends BesatzortComponent{

  constructor(
    router: Router,
    service: DbService,
    route: ActivatedRoute,
    authenticationService: AuthenticationService) {
    super(router, service, route, authenticationService);
  }

}
