import {AfterViewInit, Directive, OnInit} from '@angular/core';
import { DbService } from '../_services/db.service';
import { Router} from '@angular/router';
import {Auswahl} from '../_models/auswahl';
import * as L from 'leaflet';
import {Markierer} from '../_models/markierer';
import {AuthenticationService} from '../_services';
import {Subscription} from 'rxjs';
import {User} from '../_models/user';



@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class MarkiererComponent implements OnInit, AfterViewInit {
  markierer: Markierer;
  markers: Markierer[];
  filter = '';
  info = '';
  map;
  fishIcon;
  public isLoading = false;
  layers;
  layers2;
  currentUserSubscription: Subscription;
  currentUser: User;



  constructor(
      private router: Router,
      private service: DbService,
      private authenticationService: AuthenticationService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  filterArten(): void {
        this.service.getAllFilter('markers', 'name', this.filter)
          .subscribe(arten => {
            this.markers = arten;
            this.layers2.clearLayers();
            arten.forEach(ort => {
              if (ort.lat > 0) {
                this.layers2.addLayer(L.marker([ort.lat, ort.lon], {icon: this.fishIcon}).bindPopup(ort.name));
              }
            });
          });
  }


  // tslint:disable-next-line:typedef
  ngOnInit() {
    if (this.currentUser.rechte > 3) {
      this.router.navigate(['/']);
    }
    this.getMarkierer();
    this.markierer = { id: 0, name: '', lat: 0, lon: 0};
  }

  initMap(): void {

    this.fishIcon = L.icon({
      iconUrl: 'FishTrek.png',
      shadowUrl: 'schatten.png',

      iconSize:     [50, 50], // size of the icon
      shadowSize:   [2, 2], // size of the shadow
      iconAnchor:   [10, 10], // point of the icon which will correspond to marker's location
      shadowAnchor: [2, 31],  // the same for the shadow
      popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
    });


    this.map = L.map('map', {
            center: [51, 9],
            zoom: 4,
          });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            // tslint:disable-next-line:object-literal-sort-keys
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          });

    tiles.addTo(this.map);

    this.layers = L.layerGroup();
    this.map.addLayer(this.layers);
    this.layers2 = L.layerGroup();
    this.map.addLayer(this.layers2);

    this.map.on('click', <LeafletMouseEvent>(e) => {
      this.markierer.lat = e.latlng.lat;
      this.markierer.lon = e.latlng.lng;
      this.layers.clearLayers();
      this.layers.addLayer(L.marker([e.latlng.lat, e.latlng.lng], {icon: this.fishIcon}));
    });
    this.service.getAllFilter('markers', 'name', this.filter)
      .subscribe(projekte => {
        projekte.forEach(ort => {
          if (ort.lat > 0) {
            this.layers2.addLayer(L.marker([ort.lat, ort.lon], {icon: this.fishIcon}).bindPopup(ort.name));
          }
        });
      });
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  getMarkierer(): void {
    this.service.getAll('markierers').subscribe(
      markierers => this.markers = markierers
    );
  }

  // tslint:disable-next-line:typedef
  displayFn(kunde: Auswahl) {
    if (kunde) {
      return kunde.name; }
  }

  addPosten(): void {
    this.service.insert('markierer', this.markierer).subscribe(
      () => {
        this.markierer = { id: 0, name: '', lat: 0, lon: 0};
        this.filterArten();
        this.info = 'Erfolgreich gespeichert';
        setTimeout( () => { this.info = ''; }, 5000 );
      }
    );

  }

  goBack(): void {
    this.router.navigate(['/']);
  }

  save(marker: Markierer): void {
    this.service.update('markierer', marker)
        .subscribe(
            () => {
              this.filterArten();
              this.info = 'Erfolgreich gespeichert';
              setTimeout( () => { this.info = ''; }, 5000 );
            }
        );
  }

}


