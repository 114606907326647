
<div class="bgimg"><div style="text-align: center;"><h2><strong>gefundene Fische</strong></h2><br>
  Fische nach Funddatum filtern:   <input type="date" [(ngModel)]="von" ><br><input type="date" [(ngModel)]="bis" ><br>
  <button type="button" (click)="filterFische()">Fische filtern</button><br><br>
  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Fischart</strong></th><th><strong>Datum</strong></th>
    <tbody>
    <tr *ngFor="let fisch of fische">
      <td><a routerLink="/fund/{{fisch.id}}">{{fisch.fisch}}</a></td>
      <td>{{fisch.datum | date:'dd.MM.yyyy'}}</td>
    </tr>
    </tbody>
  </table></div></div>
