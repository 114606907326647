
<div class="bgimg"><div style="text-align: center;"><h2><strong>gefundene Fische</strong></h2><br>

  <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
    <thead>
    <th><strong>Fischart</strong></th><th><strong>Sendertyp</strong></th><th><strong>Datum</strong></th>
    <th><strong>Länge [cm]</strong></th><th><strong>Gewicht [g]</strong></th><th><strong>Gewässer</strong></th>
    <th><strong>Ort</strong></th>
    </thead>
    <tbody>
    <tr>
      <td >
        <mat-form-field>
          <input matInput placeholder="Wählen Sie eine Fischart" aria-label="Fisch" [(ngModel)]="fischtyp" (ngModelChange)="changeFisch()" [formControl]='fischeControl' [matAutocomplete]="auto2" >
          <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
            <ng-container *ngIf="!isLoading">
              <mat-option *ngFor="let fisch of filteredFische" [value]="fisch">
                <span>{{ fisch.name }}</span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
      </td>
      <td >
        <mat-form-field>
          <mat-select placeholder="Wählen Sie den Sendertyp" [(ngModel)]="gefunden.sid" name="send">
            <mat-option *ngFor="let send of senders" [value]="send.id">
              {{send.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
      <td ><mat-form-field><input type="date" placeholder="Wählen Sie das Datum" matInput [(ngModel)]="gefunden.datum"></mat-form-field></td>
      <td ><mat-form-field><input id="gefunden.gewict2" type="number" placeholder="Geben Sie die Länge an" matInput [(ngModel)]="gefunden.laenge" /></mat-form-field></td>
      <td ><mat-form-field><input id="gefunden.laenge2" type="number" placeholder="Geben Sie das Gewicht an" matInput [(ngModel)]="gefunden.gewicht" /></mat-form-field></td>
      <td >
        <mat-form-field>
          <input matInput placeholder="Wählen Sie ein Gewässer" aria-label="Fluss" [(ngModel)]="fluss" (ngModelChange)="changeFluss()" [formControl]='fluesseControl' [matAutocomplete]="auto" >
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
            <ng-container *ngIf="!isLoading">
              <mat-option *ngFor="let fluss of filteredFluesse" [value]="fluss">
                <span>{{ fluss.name }}</span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
      </td>
      <td ><mat-form-field><input id="gefunden.ort23" type="text" placeholder="Geben Sie den Fundort ein"  matInput [(ngModel)]="gefunden.fort" /></mat-form-field></td>
    </tr>
    </tbody>
  </table>
  <button type="button" (click)="save()">gef. Fisch hinzufügen</button><br><br>
  <strong>{{info}}</strong><br><br>
  Fische nach Funddatum filtern:   <input type="date" [(ngModel)]="von" ><input type="date" [(ngModel)]="bis" >
  <button type="button" (click)="filterFische()">Fische filtern</button><br><br>
  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Fischart</strong></th><th><strong>Sender</strong></th><th><strong>Datum</strong></th>
    <th><strong>Länge</strong></th><th><strong>Gewicht</strong></th><th><strong>Gewässer</strong></th>
    <th><strong>löschen</strong></th>
    <tbody>
    <tr *ngFor="let fisch of fische">
      <td><a routerLink="/fund/{{fisch.id}}">{{fisch.fisch}}</a></td>
      <td>{{fisch.sender}}</td>
      <td>{{fisch.datum | date:'dd.MM.yyyy'}}</td>
      <td>{{fisch.laenge}}</td>
      <td>{{fisch.gewicht}}</td>
      <td>{{fisch.fluss}}</td>
      <td><button (click)="erase(fisch)">löschen</button></td>
    </tr>
    </tbody>
  </table></div></div>
