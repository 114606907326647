<div class="bgimg"><div style="text-align: center;"><h2><strong>Einstellungen von {{getEinstellung('name').inhalt | uppercase}}</strong></h2><br>

  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Name (1.Zeile)</strong></th><th><strong>Name (2.Zeile)</strong></th><th><strong>Name (3.Zeile)</strong></th>
    <th><strong>Straße</strong></th><th><strong>PLZ</strong></th><th><strong>Ort</strong></th>
    </thead>
    <tbody>
    <tr>
      <td><input id="name" type="text" [(ngModel)]="getEinstellung('name').inhalt"></td>
      <td><input id="name2" type="text" [(ngModel)]="getEinstellung('name2').inhalt"/></td>
      <td><input id="name3" type="text" [(ngModel)]="getEinstellung('name3').inhalt"/></td>
      <td><input id="strasse" type="text" [(ngModel)]="getEinstellung('strasse').inhalt"/></td>
      <td><input id="plz" type="text" [(ngModel)]="getEinstellung('plz').inhalt"/></td>
      <td><input id="ort" type="text" [(ngModel)]="getEinstellung('ort').inhalt"/></td>
    </tr>
    </tbody>
  </table>
  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Telefon</strong></th><th><strong>Handy</strong></th><th><strong>E-Mail</strong></th>
    <th><strong>IBAN</strong></th><th><strong>BIC</strong></th><th><strong>Konto Inhaber</strong></th>
    </thead>
    <tbody>
    <tr>
      <td><input id="telefon" type="text" [(ngModel)]="getEinstellung('telefon').inhalt"></td>
      <td><input id="handy" type="text" [(ngModel)]="getEinstellung('handy').inhalt"/></td>
      <td><input id="email" type="text" [(ngModel)]="getEinstellung('email').inhalt"/></td>
      <td><input id="iban" type="text" [(ngModel)]="getEinstellung('iban').inhalt"/></td>
      <td><input id="bic" type="text" [(ngModel)]="getEinstellung('bic').inhalt"/></td>
      <td><input id="inhaber" type="text" [(ngModel)]="getEinstellung('inhaber').inhalt"/></td>
    </tr>
    </tbody>
  </table>
  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Steuernummer</strong></th><th><strong>Webseite</strong></th><th><strong>Firmenlogo</strong></th>
    </thead>
    <tbody>
    <tr>
      <td><input id="stnr" type="text" [(ngModel)]="getEinstellung('stnr').inhalt"></td>
      <td><input id="website" type="text" [(ngModel)]="getEinstellung('website').inhalt"/></td>
      <td><input id="logo" type="text" [(ngModel)]="getEinstellung('logo').inhalt"/></td>
    </tr>
    </tbody>
  </table>
  <div style="text-align: center;"><h2><strong>Projektphasen</strong></h2><br>

    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>ID</strong></th><th><strong>Name</strong></th>
      <th><strong>Name (Englisch)</strong></th><th><strong>löschen</strong></th>
      </thead>
      <tbody>
      <tr *ngFor="let phase of phasen">
        <td>{{phase.id}}</td>
        <td><input id="phase.name" type="text" [(ngModel)]="phase.name" /></td>
        <td><br><button (click)="eraseZeile(phase.id)"><strong>-</strong></button></td>
      </tr>
      </tbody>
    </table>
    <br>
    <button (click)="addZeile()">Phase hinzufügen</button>
    <br><br></div>
  <div style="text-align: center;"><h2><strong>Postensortierung in der Kalkulation</strong></h2><br>

    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Position</strong></th><th><strong>Name</strong></th>
      </thead>
      <tbody>
      <tr *ngFor="let typ of typen">
        <td><input id="typ.id" type="number" [(ngModel)]="typ.id" /></td>
        <td><input id="typ.name" type="text" [(ngModel)]="typ.name" /></td>
        <td><br><button (click)="eraseZeile2(typ.id)"><strong>-</strong></button></td>
      </tr>
      </tbody>
    </table>
    <br>
    <button (click)="addZeile2()">Sortierungseinheit hinzufügen</button>
    <br><br></div>
  <button (click)="save()">Einstellungen speichern</button>&nbsp;&nbsp;<br><br></div></div>
