import { Component, OnInit } from '@angular/core';
import { DbService } from '../_services/db.service';
import { Router, ActivatedRoute } from '@angular/router';
import {Auswahl} from '../_models/auswahl';
import {User} from '../_models/user';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../_services';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fluss-app',
  templateUrl: './fluesse.component.html',
  styleUrls: ['../home/home.component.css']})
export class FluesseComponent implements OnInit {
  art: Auswahl;
  arten: Auswahl[];
  filter = '';
  info = '';
  currentUser: User;
  currentUserSubscription: Subscription;


  constructor(
      private router: Router,
      private service: DbService,
      private authenticationService: AuthenticationService
) {
  this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
    this.currentUser = user;
  });
}

  filterArten(): void {
        this.service.getAllFilter('fluss', 'name', this.filter)
          .subscribe(arten => this.arten = arten);
  }


  // tslint:disable-next-line:typedef
  ngOnInit() {
    if (this.currentUser.rechte > 3) {
      this.router.navigate(['/']);
    }
    this.filterArten();
    this.art = { id: 0, name: ''};
  }

  addPosten(): void {
    if (this.art.id === 0){
      this.info = 'Gewässerkennzahl darf nicht 0 sein!';
      setTimeout( () => { this.info = ''; }, 5000 );
    }else{
      this.service.insert('fluss', this.art).subscribe(
        () => {
          this.art = { id: 0, name: ''};
          this.filterArten();
          this.info = 'Erfolgreich gespeichert';
          setTimeout( () => { this.info = ''; }, 5000 );
        }
      );
    }
  }

  goBack(): void {
    this.router.navigate(['/']);
  }

  save(fluss: Auswahl): void {
    this.service.update('fluss', fluss)
        .subscribe(
            () => {
              this.filterArten();
              this.info = 'Erfolgreich gespeichert';
              setTimeout( () => { this.info = ''; }, 5000 );
            }
        );
  }

}


