<div class="map-container">
  <div class="map-frame3">
    <div id="map"></div>
  </div>
  <div class="bgimg4"><div style="text-align: center;"><h2><strong>Markierer ändern und hinzufügen</strong></h2><br>

  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Name</strong></th>
    </thead>
    <tbody>
    <tr>
      <td><input id="name" type="text" [(ngModel)]="markierer.name"/></td>
    </tr>
    </tbody>
  </table><br><button (click)="addPosten()">Markierer hinzufügen</button><button (click)="goBack()">Zurück zum Hauptmenü</button>
  <br><br>

  Markierer filtern:   <input type="text" [(ngModel)]="filter" (ngModelChange)="filterArten()"><br><br>
  <br><br>
  <strong>{{info}}</strong><br>

  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Name</strong></th>
    </thead>
    <tbody>
    <tr *ngFor="let marker of markers">
      <td><input id="art.name" type="text" [(ngModel)]="marker.name" (keyup.enter)="save(marker)"/></td>
    </tbody>
  </table>
  <br>
  </div></div></div>
