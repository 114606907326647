import {OnInit, Directive } from '@angular/core';
import { DbService } from '../_services/db.service';
import {Gefunden} from '../_models/gefunden';
import {AuthenticationService} from '../_services';
import {User} from '../_models/user';
import {formatDate} from '@angular/common';
import {Auswahl} from '../_models/auswahl';
import {FormControl} from '@angular/forms';
import {debounceTime, finalize, switchMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';


@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class GefundenComponent implements OnInit {

  fische: Gefunden[];
  von = '';
  bis = '';
  currentUser: User;
  gefunden: Gefunden;

  public filteredFluesse: Auswahl[];
  public filteredFische: Auswahl[];
  public filteredCode: Auswahl[];
  fluesseControl = new FormControl();
  fischeControl = new FormControl();
  senderControl = new FormControl();
  public isLoading = false;

  public senders: Auswahl[];
  public sender: Auswahl;
  public fischtyp: Auswahl;
  public fluss: Auswahl;
  public fisch: Auswahl;
  public info = '';


  constructor(private service: DbService,
              private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  getFische(): void {
    if (this.currentUser.rechte > 3){
      this.service.getAllWhere('gefunden2', '', this.currentUser.markierer)
        .subscribe(projekte => this.fische = projekte);
    }else{
      this.service.getAll('gefunden')
        .subscribe(projekte => this.fische = projekte);
    }
  }

  filterFische(): void {
    if (this.von !== '' || this.bis !== ''){
      if (this.currentUser.rechte > 3){
        this.service.getAllWhereAnd2('funddatum2', 'datum', this.von, this.bis, this.currentUser.markierer)
          .subscribe(projekte => this.fische = projekte);
      }else{
        this.service.getAllWhereAnd2('funddatum', 'datum',  this.von, this.bis, this.currentUser.markierer)
          .subscribe(projekte => this.fische = projekte);
      }
    }else{
      this.getFische();
    }
  }

  erase(fish: Gefunden): void {
    this.service.update('delgeffisch', fish).subscribe(
      () => {
        this.fische = this.fische.filter(x => x.id !== fish.id);
      }
    );
  }


  // tslint:disable-next-line:typedef
  ngOnInit() {
    this.gefunden =  { id: 0, fid: 0, fisch: '', sid: 0, sender: '', flid: 0, fluss: '', fischid: 0, findid: 0,
      datum:  formatDate(new Date(), 'yyyy_MM_dd', 'en'), laenge: 0, gewicht: 0, xcord: 0, ycord: 0,
      notiz: '', bearbeitet: 0, fort: '', distanz: 0, zeit: 0};
    this.getFische();

    this.service.getAll('senders')
      .subscribe(senders => this.senders = senders);

    this.fluesseControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredFluesse = [];
          this.isLoading = true;
        }),
        switchMap(value => this.obsFluesse(value)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
          )
        )
      )
      .subscribe(
        data => this.filteredFluesse = data
      );

    this.fischeControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredFische = [];
          this.isLoading = true;
        }),
        switchMap(value => this.obsFische(value)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
          )
        )
      )
      .subscribe(
        data => this.filteredFische = data
      );

    this.senderControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredCode = [];
          this.isLoading = true;
        }),
        switchMap(value => this.obsCode(value)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
          )
        )
      )
      .subscribe(
        data => this.filteredCode = data
      );
  }

  obsFluesse(value: string): Observable<Auswahl[]> {
    return this.service.getAllFilter('fluss', 'name', value);
  }
  obsFische(value: string): Observable<Auswahl[]> {
    return this.service.getAllFilter('fisch', 'name', value);
  }
  obsCode(value: string): Observable<Auswahl[]> {
    return this.service.getAllFilterAnd('code', 'code', value, this.gefunden.sid, this.gefunden.fid);
  }

  displayFn(kunde: Auswahl): string {
    if (kunde) {
      return kunde.name; }
  }

  changeFluss(): void {
    this.gefunden.flid = this.fluss.id;
  }

  changeFisch(): void {
    this.gefunden.fid = this.fischtyp.id;
  }

  save(): void {
    this.service.insert('gefunden', this.gefunden)
      .subscribe(data => {
        this.info = 'Daten übertragen!';
        setTimeout( () => { this.info = ''; }, 5000 );
      });
  }
}
