import { NgModule } from '@angular/core';
import { PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';
import { HomeDesktopComponent } from './home/home.component.desktop';
import { HomeMobileComponent } from './home/home.component.mobile';
import { AuthGuard } from './_guards';
import {FischartenComponent} from './fischarten/fischarten.component';
import {UserComponent} from './user/user.component';
import {EinstellungenDesktopComponent} from './einstellungen/einstellungen.component.desktop';
import {LoginMobileComponent} from './login/login.component.mobile';
import {LoginDesktopComponent} from './login/login.component.desktop';
import {FischMobileComponent} from './fisch/fisch.component.mobile';
import {FischDesktopComponent} from './fisch/fisch.component.desktop';
import {FischeMobileComponent} from './fische/fische.component.mobile';
import {FischeDesktopComponent} from './fische/fische.component.desktop';
import {FluesseComponent} from './fluesse/fluesse.component';
import {GefundenMobileComponent} from './gefunden/gefunden.component.mobile';
import {GefundenDesktopComponent} from './gefunden/gefunden.component.desktop';
import {BesatzortDesktopComponent} from './besatzort/besatzort.component.desktop';
import {BesatzortMobileComponent} from './besatzort/besatzort.component.mobile';
import {BesatzorteDesktopComponent} from './besatzorte/besatzorte.component.desktop';
import {BesatzorteMobileComponent} from './besatzorte/besatzorte.component.mobile';
import {FundDesktopComponent} from './fund/fund.component.desktop';
import {FundMobileComponent} from './fund/fund.component.mobile';
import {ProjekteDesktopComponent} from './projekte/projekte.component.desktop';
import {ProjekteMobileComponent} from './projekte/projekte.component.mobile';
import {MarkiererDesktopComponent} from './markierer/markierer.component.desktop';


const appRoutes: Routes = [
  { path: '', component: HomeDesktopComponent, canActivate: [AuthGuard] },
  { path: 'home2', component: HomeMobileComponent, canActivate: [AuthGuard] },
  { path: 'gewaesser', component: FluesseComponent, canActivate: [AuthGuard] },
  { path: 'markierer', component: MarkiererDesktopComponent, canActivate: [AuthGuard] },
  { path: 'gefunden2', component: GefundenMobileComponent, canActivate: [AuthGuard] },
  { path: 'gefunden', component: GefundenDesktopComponent, canActivate: [AuthGuard] },
  { path: 'besatzort/:id', component: BesatzortDesktopComponent, canActivate: [AuthGuard] },
  { path: 'besatzort2/:id', component: BesatzortMobileComponent, canActivate: [AuthGuard] },
  { path: 'besatzorte/:id', component: BesatzorteDesktopComponent, canActivate: [AuthGuard] },
  { path: 'besatzorte2/:id', component: BesatzorteMobileComponent, canActivate: [AuthGuard] },
  { path: 'fund/:id', component: FundDesktopComponent, canActivate: [AuthGuard] },
  { path: 'fund2/:id', component: FundMobileComponent, canActivate: [AuthGuard] },
  { path: 'projekte', component: ProjekteDesktopComponent, canActivate: [AuthGuard] },
  { path: 'projekte2', component: ProjekteMobileComponent, canActivate: [AuthGuard] },
  { path: 'fisch/:id', component:  FischDesktopComponent, canActivate: [AuthGuard] },
  { path: 'fisch2/:id', component:  FischMobileComponent, canActivate: [AuthGuard] },
  { path: 'fische2', component: FischeMobileComponent, canActivate: [AuthGuard] },
  { path: 'fische', component:  FischeDesktopComponent, canActivate: [AuthGuard] },
  { path: 'user', component: UserComponent, canActivate: [AuthGuard] },
  { path: 'fischarten', component: FischartenComponent, canActivate: [AuthGuard] },
  { path: 'login2', component: LoginMobileComponent },
  { path: 'login', component: LoginDesktopComponent },
  { path: 'einstellungen', component: EinstellungenDesktopComponent , canActivate: [AuthGuard] },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

const mobiRoutes: Routes = [
  { path: '', component: HomeMobileComponent, canActivate: [AuthGuard] },
  { path: '', component:  HomeDesktopComponent, canActivate: [AuthGuard] },
  { path: 'gewaesser', component: FluesseComponent, canActivate: [AuthGuard] },
  { path: 'markierer', component: MarkiererDesktopComponent, canActivate: [AuthGuard] },
  { path: 'gefunden', component: GefundenMobileComponent, canActivate: [AuthGuard] },
  { path: 'gefunden3', component: GefundenDesktopComponent, canActivate: [AuthGuard] },
  { path: 'besatzort3/:id', component: BesatzortDesktopComponent, canActivate: [AuthGuard] },
  { path: 'besatzort/:id', component: BesatzortMobileComponent, canActivate: [AuthGuard] },
  { path: 'besatzorte3/:id', component: BesatzorteDesktopComponent, canActivate: [AuthGuard] },
  { path: 'besatzorte/:id', component: BesatzorteMobileComponent, canActivate: [AuthGuard] },
  { path: 'fund3/:id', component: FundDesktopComponent, canActivate: [AuthGuard] },
  { path: 'fund/:id', component: FundMobileComponent, canActivate: [AuthGuard] },
  { path: 'projekte3', component: ProjekteDesktopComponent, canActivate: [AuthGuard] },
  { path: 'projekte', component: ProjekteMobileComponent, canActivate: [AuthGuard] },
  { path: 'fisch/:id', component: FischMobileComponent, canActivate: [AuthGuard] },
  { path: 'fisch3/:id', component:  FischDesktopComponent, canActivate: [AuthGuard] },
  { path: 'fische', component: FischeMobileComponent, canActivate: [AuthGuard] },
  { path: 'fische3', component:  FischeDesktopComponent, canActivate: [AuthGuard] },
  { path: 'fischarten', component: FischartenComponent, canActivate: [AuthGuard] },
  { path: 'user', component: UserComponent, canActivate: [AuthGuard] },
  { path: 'fischarten', component: FischartenComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginMobileComponent },
  { path: 'login3', component: LoginDesktopComponent },
  { path: 'einstellungen', component: EinstellungenDesktopComponent , canActivate: [AuthGuard] },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

// export const routing: Routes = RouterModule.forRoot(appRoutes);


@NgModule({
  // as default we set the desktop routing configuration. if mobile will be started it will be replaced below.
  // note that we must specify some routes here (not an empty array) otherwise the trick below doesn't work...
  imports: [RouterModule.forRoot(appRoutes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})

// export const routing = RouterModule.forRoot(appRoutes);
// tslint:disable-next-line:class-name
export class routing {

  public constructor(private router: Router) {

    if (window.innerWidth < 768) {
      router.resetConfig(mobiRoutes);
    }

  }


  /**
   * this function inject new routes for the given module instead the current routes. the operation happens on the given current routes object so after
   * this method a call to reset routes on router should be called with the the current routes object.
   * @param currentRoutes
   * @param routesToInject
   * @param childNameToReplaceRoutesUnder - the module name to replace its routes.
   */
  private injectModuleRoutes(currentRoutes: Routes, routesToInject: Routes, childNameToReplaceRoutesUnder: string): void {
    for (let i = 0; i < currentRoutes.length; i++) {
      if (currentRoutes[i].loadChildren != null &&
        currentRoutes[i].loadChildren.toString().indexOf(childNameToReplaceRoutesUnder) != -1) {
        // we found it. taking the route prefix
        const prefixRoute: string = currentRoutes[i].path;
        // first removing the module line
        currentRoutes.splice(i, 1);
        // now injecting the new routes
        // we need to add the prefix route first
        this.addPrefixToRoutes(routesToInject, prefixRoute);
        for (const route of routesToInject) {
          currentRoutes.push(route);
        }
        // since we found it we can break the injection
        return;
      }

      if (currentRoutes[i].children != null) {
        this.injectModuleRoutes(currentRoutes[i].children, routesToInject, childNameToReplaceRoutesUnder);
      }
    }
  }

  // tslint:disable-next-line:typedef
  private addPrefixToRoutes(routes: Routes, prefix: string) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < routes.length; i++) {
      routes[i].path = prefix + '/' + routes[i].path;
    }
  }
}
