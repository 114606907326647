import {AfterViewInit, Directive, OnInit} from '@angular/core';
import { DbService } from '../_services/db.service';
import { Router} from '@angular/router';
import {Auswahl} from '../_models/auswahl';
import {debounceTime, finalize, switchMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {Projekt} from '../_models/projekt';
import * as L from 'leaflet';
import { AuthenticationService } from '../_services';
import { User } from '../_models/user';
import {Markierer} from '../_models/markierer';


@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class ProjekteComponent implements OnInit, AfterViewInit {
  projekt: Projekt;
  projekte: Projekt[];
  markierer: Markierer[];
  marker: Markierer;
  laendercode: Auswahl[];
  filteredFluesse: Auswahl[];
  fluss: Auswahl;
  filter = '';
  info = '';
  map;
  fishIcon;
  fluesseControl = new FormControl();
  public isLoading = false;
  layers;
  layers2;
  currentUser: User;


  constructor(
      private router: Router,
      private service: DbService,
      private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  filterArten(): void {
    if (this.currentUser.rechte > 3){
      this.service.getAllFilterAnd('projekte2', 'name', '', this.currentUser.markierer, 0)
        .subscribe(projekte => {
          this.projekte = projekte;
          projekte.forEach(ort => {
              if (ort.lat > 0) {
                this.layers2.addLayer(L.marker([ort.lat, ort.lon], {icon: this.fishIcon}).bindPopup(ort.name));
              }
          });
        });

    }else{
      this.service.getAllFilter('projekte', 'name', '')
        .subscribe(projekte => {
          this.projekte = projekte;
          projekte.forEach(ort => {
              if (ort.lat > 0) {
                this.layers2.addLayer(L.marker([ort.lat, ort.lon], {icon: this.fishIcon}).bindPopup(ort.name));
              }
          });
        });
    }
  }


  // tslint:disable-next-line:typedef
  ngOnInit() {
    this.filterArten();
    this.getLaender();
    this.getMarkierer();
    this.service.getConcrete('markierer', this.currentUser.markierer).subscribe(
      markierer => this.marker = markierer
    );
    this.fluss = { id: 0, name: ''};
    if (this.currentUser.rechte > 3){
      this.service.getConcrete('markierer', this.currentUser.markierer).subscribe(
        markierer => {
          this.projekt = {
            id: 0, name: '', laendercode: 'GER', mid: markierer.id,
            markierer: markierer.name, flid: 0, fluss: '', lat: 0, lon: 0
          };
        }
      );
    }else{
      this.projekt = { id: 0, name: '', laendercode: 'GER', mid: 0, markierer: '', flid: 0, fluss: '', lat: 0, lon: 0};
    }

    this.fluesseControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredFluesse = [];
          this.isLoading = true;
        }),
        switchMap(value => this.obsFluesse(value)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
          )
        )
      )
      .subscribe(
        data => this.filteredFluesse = data
      );
  }

  initMap(): void {

    this.fishIcon = L.icon({
      iconUrl: 'FishTrek.png',
      shadowUrl: 'schatten.png',

      iconSize:     [50, 50], // size of the icon
      shadowSize:   [2, 2], // size of the shadow
      iconAnchor:   [10, 10], // point of the icon which will correspond to marker's location
      shadowAnchor: [2, 31],  // the same for the shadow
      popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
    });


    this.map = L.map('map', {
            center: [51, 9],
            zoom: 4,
          });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            // tslint:disable-next-line:object-literal-sort-keys
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          });

    tiles.addTo(this.map);

    this.layers = L.layerGroup();
    this.map.addLayer(this.layers);
    this.layers2 = L.layerGroup();
    this.map.addLayer(this.layers2);


    this.map.on('click', <LeafletMouseEvent>(e) => {
            this.projekt.lat = e.latlng.lat;
            this.projekt.lon = e.latlng.lng;
            this.layers.clearLayers();
            this.layers.addLayer(L.marker([e.latlng.lat, e.latlng.lng], {icon: this.fishIcon}));
          });


    if (this.currentUser.rechte > 3){
      this.service.getAllFilterAnd('projekte2', 'name', '', this.currentUser.markierer, 0)
        .subscribe(projekte => {
          projekte.forEach(ort => {
            if (ort.lat > 0) {
              if (ort.lat > 0) {
                this.layers2.addLayer(L.marker([ort.lat, ort.lon], {icon: this.fishIcon}).bindPopup(ort.name));
              }
            }
          });
        });

    }else{
      this.service.getAllFilter('projekte', 'name', '')
        .subscribe(projekte => {
          projekte.forEach(ort => {
            if (ort.lat > 0) {
              if (ort.lat > 0) {
                this.layers2.addLayer(L.marker([ort.lat, ort.lon], {icon: this.fishIcon}).bindPopup(ort.name));
              }
            }
          });
        });
    }
  }

  ngAfterViewInit(): void {
    this.initMap();
  }


  obsFluesse(value: string): Observable<Auswahl[]> {
    return this.service.getAllFilter('fluss', 'name', value);
  }

  getMarkierer(): void {
    this.service.getAll('markierers').subscribe(
      markierers => this.markierer = markierers
    );
  }

  getLaender(): void {
    this.service.getAll('laender').subscribe(
      markierers => this.laendercode = markierers
    );
  }


  changeFluss(): void {
    this.projekt.flid = this.fluss.id;
  }

  // tslint:disable-next-line:typedef
  displayFn(kunde: Auswahl) {
    if (kunde) {
      return kunde.name; }
  }

  addPosten(): void {
    this.service.insert('projekte', this.projekt).subscribe(
      () => {
        if (this.currentUser.rechte > 3){
          this.projekt = { id: 0, name: '', laendercode: 'GER', mid: this.markierer[this.currentUser.markierer - 1].id,
            markierer: this.markierer[this.currentUser.markierer - 1].name, flid: 0, fluss: '', lat: 0, lon: 0};
        }else{
          this.projekt = { id: 0, name: '', laendercode: 'GER', mid: 0, markierer: '', flid: 0, fluss: '', lat: 0, lon: 0};
        }
        this.filterArten();
        this.info = 'Erfolgreich gespeichert';
        setTimeout( () => { this.info = ''; }, 5000 );
      }
    );

  }

  goBack(): void {
    this.router.navigate(['/']);
  }

  save(projekt: Projekt): void {
    this.service.update('projekte', projekt)
        .subscribe(
            () => {
              this.filterArten();
              this.info = 'Erfolgreich gespeichert';
              setTimeout( () => { this.info = ''; }, 5000 );
            }
        );
  }

}


