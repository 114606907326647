<div class="bgimg"><div style="text-align: center;"><h2><strong>Fische am Besatzort {{besatzort.name}}</strong></h2><br>

  <div class="map-frame2">
    <div id="map"></div>
  </div>

  <div class="bgimg3"><div style="text-align: center;">
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Code</strong></th>
      </thead>
      <tbody>
      <tr>
        <td><input id="name" type="text" [(ngModel)]="code.code"/></td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Fischart</strong></th>
      </thead>
      <tbody>
      <tr>
        <td>
          <mat-form-field>
            <input matInput placeholder="Wählen Sie eine Fischart" aria-label="Fisch" [(ngModel)]="fisch" (ngModelChange)="changeFisch()" [formControl]='fischeControl' [matAutocomplete]="auto2" >
            <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
              <ng-container *ngIf="!isLoading">
                <mat-option *ngFor="let fisch of filteredFische" [value]="fisch">
                  <span>{{ fisch.name }}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
        </td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Sender</strong></th>
      </thead>
      <tbody>
      <tr>
        <td><mat-form-field>
          <mat-select placeholder="Wählen Sie den Sendertyp" [(ngModel)]="code.sid" name="send">
            <mat-option *ngFor="let send of senders" [value]="send.id">
              {{send.name}}
            </mat-option>
          </mat-select>
        </mat-form-field></td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Datum</strong></th>
      </thead>
      <tbody>
      <tr>
        <td><input type="date" [(ngModel)]="code.datum"></td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Uhrzeit</strong></th>
      </thead><tbody>
      <tr>
        <td><input type="date" [(ngModel)]="code.datum"></td>
        <td><input type="time" [(ngModel)]="zeit"></td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Länge</strong></th>
      </thead>
      <tbody>
      <tr>
        <td><input type="number" [(ngModel)]="code.laenge"></td>
      </tr>
      </tbody>
    </table>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Gewicht</strong></th>
      </thead>
      <tbody>
      <tr>
        <td><input type="number" [(ngModel)]="code.gewicht"></td>
      </tr>
      </tbody>
    </table>
    <br><button (click)="addPosten()">Fisch hinzufügen</button><button (click)="goBack()">Zurück zum Hauptmenü</button>
  </div></div>
  <br><br>
  <div class="bgimg3"><div style="text-align: center;">
    Fische nach  Code filtern:   <input type="text" [(ngModel)]="filter" (ngModelChange)="filterArten()"><br><br>
    <br><br>
    <strong>{{info}}</strong><br>

    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
      <thead>
      <th><strong>Code</strong></th>
      </thead>
      <tbody>
      <tr *ngFor="let fisch of filteredCode">
        <td><a routerLink="/fisch/{{fisch.id}}">{{fisch.code}}</a></td>
      </tr>
      </tbody>
    </table>
    <br></div></div>
</div></div>
