import {Component} from '@angular/core';
import { DbService } from '../_services/db.service';
import {ProjekteComponent} from './projekte.component';
import {Router} from '@angular/router';
import {AuthenticationService} from '../_services';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'projekte-app',
  templateUrl: './projekte.component.html',
  styleUrls: ['../app.component.css']})

export class ProjekteDesktopComponent extends ProjekteComponent{

  constructor(
    router: Router,
    service: DbService,
    authenticationService: AuthenticationService) {
    super(router, service, authenticationService);
  }

}
