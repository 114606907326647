<div class="bgimg">
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1"><tr><td>
        <div style="text-align: center;">
          <img src="https://www.fish-trek.de/FishTrek.png" width="66%">
        </div></td></tr></table>
    Hi {{currentUser.mname}}!
  <h2><strong>gefundene Fische</strong></h2><br>
  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Fischart</strong></th><th><strong>Datum</strong></th>
    <tbody>
    <tr *ngFor="let fisch of fische">
      <td><a routerLink="/fund/{{fisch.id}}">{{fisch.fisch}}</a></td>
      <td>{{fisch.datum | date:'dd.MM.yyyy'}}</td>
    </tr>
    </tbody>
  </table><br><br>

</div>
