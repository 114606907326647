<div class="bgimg">
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1"><tr><td>
        <div style="text-align: center;">
          <img src="https://www.fish-trek.de/FishTrek.png">
        </div></td></tr></table>
    <h1>Hi {{currentUser.mname}}!</h1>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="1"><tr><td style="width: 25%;text-align: center;">
        <!--<table cellpadding="5" cellspacing="0" style="width: 100%" border="1"><tr><td>
            <h3><a routerLink="/fisch-anlegen/">Fisch anlegen</a><br></h3></td>
        </tr>
        </table>--></td><td  style="width: 75%">
      <h2><strong>gefundene Fische</strong></h2><br>
      <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
        <thead>
        <th><strong>Fischart</strong></th><th><strong>Sendertyp</strong></th><th><strong>Datum</strong></th>
        <th><strong>Länge</strong></th><th><strong>Gewicht</strong></th><th><strong>Gewässer</strong></th>
        <tbody>
        <tr *ngFor="let fisch of fische">
          <td><a routerLink="/fund/{{fisch.id}}">{{fisch.fisch}}</a></td>
          <td>{{fisch.sender}}</td>
          <td>{{fisch.datum | date:'dd.MM.yyyy'}}</td>
          <td>{{fisch.laenge}}</td>
          <td>{{fisch.gewicht}}</td>
          <td>{{fisch.fluss}}</td>
        </tr>
        </tbody>
      </table>
    </td></tr>
    </table><br><br>
</div>
