import {OnInit, Directive } from '@angular/core';
import { DbService } from '../_services/db.service';
import {Fisch} from '../_models/fisch';
import {AuthenticationService} from '../_services';
import {User} from '../_models/user';


@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class FischeComponent implements OnInit {

  fische: Fisch[];
  filter = '';
  currentUser: User;

  constructor(private service: DbService,
              private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  filterFische(): void {
    if (this.currentUser.rechte > 3){
      this.service.getAllFilterAnd('fischelike2', 'code', this.filter, this.currentUser.markierer, 0)
        .subscribe(projekte => this.fische = projekte);
    }else{
      this.service.getAllFilter('fischelike', 'code', this.filter)
        .subscribe(projekte => this.fische = projekte);
    }
  }


  // tslint:disable-next-line:typedef
  ngOnInit() {
    this.filterFische();
  }

}
