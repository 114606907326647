<div class="bgimg"><div style="text-align: center;"><h2><strong>Userservice</strong></h2>

  <br><br>

  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>Name</strong></th><th><strong>Rechte</strong></th><th><strong>Markierer</strong></th><th><strong>Nik</strong></th>
    <th><strong>Password</strong></th>
    </thead>
    <tbody>
    <tr>
      <td><input id="newUser.mname" type="text" [(ngModel)]="newUser.mname" /></td>
      <td><mat-form-field>
        <mat-select placeholder="Userrechte auswählen" [(ngModel)]="recht" name="right">
          <mat-option *ngFor="let right of rechte" [value]="right">
            {{right.name}}
          </mat-option>
        </mat-select>
      </mat-form-field></td>
      <td><mat-form-field>
        <mat-select placeholder="Markierer auswählen" [(ngModel)]="markierer" name="marker">
          <mat-option *ngFor="let marker of markers" [value]="marker">
            {{marker.name}}
          </mat-option>
        </mat-select>
      </mat-form-field></td>
      <td><input id="newUser.nik" type="text" [(ngModel)]="newUser.nik" /></td>
      <td><input id="newUser.pw" type="text" [(ngModel)]="newUser.pw" /></td>
    </tr>
    </tbody>
  </table><br><br><button (click)="addUser()">User hinzufügen</button>
  <button (click)="save()">User speichern</button><br><br>Nur ein Passwort eintragen, wenn es geändert werden soll.<br><br>

  <table cellpadding="5" cellspacing="0" style="width: 100%" border="1">
    <thead>
    <th><strong>ID</strong></th><th><strong>Name</strong></th><th><strong>Rechte</strong></th><th><strong>Markierer</strong></th><th><strong>Nik</strong></th>
    <th><strong>Password</strong></th>
    <th><strong>löschen</strong></th>
    </thead>
    <tbody>
    <tr  *ngFor=" let worker of user">
      <td>{{worker.id}}</td>
      <td>{{worker.mname}}</td>
      <td>{{rechte[worker.rechte-1].name}}</td>
      <td>{{markers[worker.markierer-1].name}}</td>
      <td><input id="nik" type="text" [(ngModel)]="worker.nik" /></td>
      <td><input id="pw" type="text" [(ngModel)]="worker.pw" /></td>
      <td><br><button (click)="eraseZeile(worker.id)"><strong>-</strong></button></td>
    </tr>
    </tbody>
  </table><br>


</div></div>
