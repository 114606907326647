import {AfterViewInit, Directive, OnInit} from '@angular/core';
import { DbService } from '../_services/db.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Projekt} from '../_models/projekt';
import {Besatzort} from '../_models/besatzort';
import * as L from 'leaflet';
import {AuthenticationService} from '../_services';
import {User} from '../_models/user';


@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class BesatzorteComponent implements OnInit, AfterViewInit {
  projekt: Projekt;
  besatzorte: Besatzort[];
  besatzort: Besatzort;
  map;
  filter = '';
  info = '';
  public isLoading = false;
  fishIcon;
  currentUser: User;



  constructor(
      private router: Router,
      private service: DbService,
      private route: ActivatedRoute,
      private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  getProjekt(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.service.getConcrete('projekt', id)
      .subscribe(projekt => this.projekt = projekt);
  }

  filterArten(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.service.getAllFilterAnd('besatzorte', 'name', this.filter, id, 0)
      .subscribe(arten => this.besatzorte = arten);
  }


  // tslint:disable-next-line:typedef
  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('id');
    if (this.currentUser.rechte > 3) {
      this.service.getConcrete('projekt', id)
        .subscribe(projekt => {
          {
            if (this.currentUser.markierer !== projekt.mid){
              this.router.navigate(['/']);
            }
          }
        });
    }
    this.getProjekt();
    this.filterArten();
    this.besatzort = { id: 0, name: '', pid: id, lat: 0, lon: 0};
  }

  initMap(): void {

    this.fishIcon = L.icon({
      iconUrl: 'FishTrek.png',
      shadowUrl: 'schatten.png',

      iconSize:     [50, 50], // size of the icon
      shadowSize:   [2, 2], // size of the shadow
      iconAnchor:   [10, 10], // point of the icon which will correspond to marker's location
      shadowAnchor: [2, 31],  // the same for the shadow
      popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    const id = +this.route.snapshot.paramMap.get('id');
    this.service.getConcrete('projekt', id)
      .subscribe(projekt => {
        if (projekt.lat > 0) {
              this.map = L.map('map', {
                center: [ projekt.lat, projekt.lon ],
                zoom: 9,
              });
              const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
                // tslint:disable-next-line:object-literal-sort-keys
                attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
              });

              tiles.addTo(this.map);


              this.map.on('click', <LeafletMouseEvent>(e) => {
                this.besatzort.lat = e.latlng.lat;
                this.besatzort.lon = e.latlng.lng;
              });

              this.service.getAllFilterAnd('besatzorte', 'name', this.filter, id, 0)
                .subscribe(arten => {
                  arten.forEach(ort => {
                    if (ort.lat > 0) {
                          L.marker([ort.lat, ort.lon], {icon: this.fishIcon}).bindPopup(ort.name).addTo(this.map);
                    }
                  });
                });
        }else{
      this.map = L.map('map', {
        center: [51, 9],
        zoom: 5,
      });

      const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            // tslint:disable-next-line:object-literal-sort-keys
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          });

      tiles.addTo(this.map);


      this.map.on('click', <LeafletMouseEvent>(e) => {
            this.besatzort.lat = e.latlng.lat;
            this.besatzort.lon = e.latlng.lng;
          });
    }
      });

  }

ngAfterViewInit(): void {
    this.initMap();
  }

addPosten(): void {
    this.service.insert('besatzorte', this.besatzort).subscribe(
      () => {
        const id = +this.route.snapshot.paramMap.get('id');
        this.besatzort = { id: 0, name: '', pid: id, lat: 0, lon: 0};
        this.filterArten();
        this.info = 'Erfolgreich gespeichert';
        setTimeout( () => { this.info = ''; }, 5000 );
      }
    );

  }


goBack(): void {
    this.router.navigate(['/']);
  }

save(besatzort: Besatzort): void {
    this.service.update('besatzorte', besatzort)
        .subscribe(
            () => {
              this.filterArten();
              this.info = 'Erfolgreich gespeichert';
              setTimeout( () => { this.info = ''; }, 5000 );
            }
        );
  }

}


