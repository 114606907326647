import { Component} from '@angular/core';
import { DbService } from '../_services/db.service';
import {FundComponent} from './fund.component';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-fund',
  templateUrl: './fund.component.html',
  styleUrls: ['../app.component.css']
})
export class FundDesktopComponent extends FundComponent{

  constructor(
    router: Router,
    service: DbService,
    route: ActivatedRoute) {
    super(router, service, route);
  }

}
