import { Component, OnInit, OnDestroy, AfterViewInit  } from '@angular/core';
import { HomeComponent} from './home.component';
import { AuthenticationService } from '../_services/authentication.service';
import { DbService } from '../_services/db.service';


@Component({
  // tslint:disable-next-line:component-selector
    selector: 'homemobile',
    templateUrl: './home.component.mobile.html',
    styleUrls: ['./home.component.mobile.css']
})
export class HomeMobileComponent extends HomeComponent{

    constructor(authenticationService: AuthenticationService,
                service: DbService) {
        super(authenticationService, service);
    }

}
